import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { LinearProgress, Stack } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { showModal } from "../../app/store/slice/counterSlice";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

export const FormCInputFile = ({
  name,
  control,
  label,
  setValue,
  uid,
  defaultValue,
  mandatory,
  loadInitialData,
}) => {
  const dispatch = useDispatch();

  const aRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");

  const [progress, setProgress] = useState({ range: 0, color: "inherit" });
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
          {/* <CircularProgress /> */}
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (defaultValue) {
      setImageUrl(defaultValue);
      setProgress({ range: 100, color: "success" });
    }
  }, [defaultValue]);
  const fileHandler = (e) => {
    setValue(name, e.target.files[0]);
    upload({ name: e.target.files[0] });
  };
  const dropHandler = (e) => {
    aRef.current.value = null;
    removeImage();
  };

  const upload = (data) => {
    if (uid) {
      const formData = new FormData();
      formData.append("image", data?.name);
      formData.append("uid", uid);
      formData.append("type", name);

      axios
        .post("/documentUploadFB", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = parseInt(
              (progressEvent.loaded / progressEvent.total) * 50
            );
            setProgress({ range: progress, color: "secondary" });
          },
          onDownloadProgress: (progressEvent) => {
            // const progress =
            //   parseInt(50) +
            //   (progressEvent.loaded / progressEvent.total) * 50;
            //   console.log(progressEvent);
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            setProgress({ range: 100, color: "success" });
            setImageUrl(response?.data?.data);
            loadInitialData();
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            console.log(response?.data);

            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            setProgress({ range: 100, color: "inherit" });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          dispatch(
            showModal({
              msg: msg,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const removeImage = () => {
    if (uid) {
      const payload = {
        fileName: imageUrl,
        uid: uid,
        type: name,
      };
      axios
        .post("/documentRemovalFB", payload)
        .then(function (response) {
          if (!response?.data?.error) {
            setImageUrl(response?.data?.data);
            loadInitialData();
            setProgress({ range: 0, color: "inherit" });
          } else {
            setProgress({ range: 0, color: "inherit" });
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            // toast.error(errDesc);
          }
        })
        .catch(function (error) {
          toast.error(error?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <>
          <div className="mb-3">
            <label hmlFor="formFile" className="form-label">
              {label}{" "}
              {mandatory && (
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              )}
            </label>
            <div className="row">
              <div className="col-9">
                <input
                  ref={aRef}
                  className="form-control"
                  name={name}
                  type="file"
                  control={control}
                  onChange={fileHandler}
                  accept="image/png, image/jpeg"
                />
                {progress?.range > 0 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    mt={2}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Box sx={{ width: "100%", marginTop: 1 }}>
                      <LinearProgressWithLabel
                        value={progress?.range}
                        color={progress?.color}
                      />
                    </Box>
                    {imageUrl && (
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={dropHandler}
                      >
                        Delete
                      </Button>
                    )}
                  </Stack>
                )}
              </div>
              <div className="col-3">
                <PhotoProvider>
                  <PhotoView key={name} src={imageUrl}>
                    <img
                      src={imageUrl}
                      alt=""
                      className="w-75"
                      style={{
                        maxHeight: "150px",
                        maxWidth: "120px",
                        cursor: "pointer",
                      }}
                    />
                  </PhotoView>
                </PhotoProvider>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};
