import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import toast from "react-hot-toast";
import { FormInputRadio } from "../../../components/form-components/FormInputRadio";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  PGSchemaWithDisability,
  PGSchemaWithOutDisability,
  UGSchemaWithDisability,
  UGSchemaWithOutDisability,
} from "../../../app/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTable from "../../../components/form-components/formTable";
import { showModal } from "../../../app/store/slice/counterSlice";
import FormTablePG from "../../../components/form-components/FormTablePG";

function EducationalDetails({ activeStep }) {
  const dispatch = useDispatch();
  const [defaultSchema, setDefaultSchema] = useState();
  const couseType = useSelector((state) => state.counter.couseType);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [showTextForDisability, setShowTextForDisability] = useState(false);
  const [boardType, setBoardType] = useState("");
  const [firstAttempt, setFirstAttempt] = useState(false);

  const methods = useForm({
    resolver: yupResolver(defaultSchema),
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValue,
    register,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    const submittedData = { ...data };
    const tableRow = [];
    const tableCount = boardType == "cbsc" ? 5 : 6;
    for (let i = 1; i <= tableCount; i++) {
      const row = {
        uid: userData.uid,
        type: couseType,
        part: submittedData[`part_${i}`],
        subject: submittedData[`subject_${i}`],
        marks: submittedData[`mark_${i}`],
        maximum: submittedData[`maximum_${i}`],
        register_no:
          submittedData?.first_attempt == "Y"
            ? submittedData?.twelvelth_reg_mark
            : submittedData[`register_no_${i}`],
        no_of_attempts: submittedData[`no_of_attempts_${i}`] | 1,
        month_year: submittedData[`month_year_${i}`],
        class: submittedData[`class_${i}`],
        percentage: submittedData[`percentage_${i}`],
      };
      tableRow?.push(row);
      delete submittedData[`part_${i}`];
      delete submittedData[`subject_${i}`];
      delete submittedData[`mark_${i}`];
      delete submittedData[`maximum_${i}`];
      delete submittedData[`register_no_${i}`];
      delete submittedData[`no_of_attempts_${i}`];
      delete submittedData[`month_year_${i}`];
      delete submittedData[`class_${i}`];
      delete submittedData[`percentage_${i}`];
    }
    if (tableCount == 5) {
      if (submittedData.hasOwnProperty("part_6")) {
        if (submittedData["part_6"]) {
          delete submittedData["part_6"];
        }
      }
      if (submittedData.hasOwnProperty("subject_6")) {
        if (submittedData["subject_6"]) {
          delete submittedData["subject_6"];
        }
      }
      if (submittedData.hasOwnProperty("mark_6")) {
        if (submittedData["mark_6"]) {
          delete submittedData["mark_6"];
        }
      }
      if (submittedData.hasOwnProperty("maximum_6")) {
        if (submittedData["maximum_6"]) {
          delete submittedData["maximum_6"];
        }
      }
      if (submittedData.hasOwnProperty("month_year_6")) {
        if (submittedData["month_year_6"]) {
          delete submittedData["month_year_6"];
        }
      }
    }
    let tableRes;
    if (couseType == "UG") {
      tableRes = tableRow?.every((row) => {
        return (
          row["subject"] &&
          row["marks"] &&
          row["maximum"] &&
          row["month_year"] &&
          row["register_no"]
        );
      });
    } else {
      tableRes = tableRow?.slice(0, -1)?.every((row) => {
        return (
          row["subject"] &&
          row["marks"] &&
          row["maximum"] &&
          row["class"] &&
          row["percentage"]
        );
      });
    }

    let spliceFlag = false;
    if (
      tableRow?.[5]?.["subject"] == "" ||
      tableRow?.[5]?.["marks"] == "" ||
      tableRow?.[5]?.["maximum"] == "" ||
      tableRow?.[5]?.["class"] == "" ||
      tableRow?.[5]?.["percentage"] == ""
    ) {
      spliceFlag = true;
    }
    if (tableRes) {
      const markMaxMark = tableRow.every((row) => {
        return +row["marks"] <= +row["maximum"];
      });

      if (markMaxMark) {
        saveForm({
          ...submittedData,
          uid: userData.uid,
          markTable: spliceFlag ? tableRow?.slice(0, -1) : tableRow,
        });
      } else {
        dispatch(
          showModal({
            msg: "Marks obtained should be less than maximum marks",
            error: true,
          })
        );
      }
    } else {
      dispatch(
        showModal({
          msg: spliceFlag
            ? "Enter minimum 5 rows in the marks table"
            : "Enter all fields in the marks table",
          error: true,
        })
      );
    }
  };

  const handleDisabilityRadio = (value) => {
    value && clearErrors("differently_abled");
    if (value == "Y") {
      setShowTextForDisability(true);
    } else {
      setShowTextForDisability(false);
      clearErrors("type_of_differently_abled");
    }
  };
  const boardHandler = (value) => {
    value && clearErrors("board");
    setBoardType(value);
  };
  const handleFirstAttemptRadio = (value) => {
    value && clearErrors("first_attempt");
    if (value == "Y") {
      setFirstAttempt(true);
    } else {
      setFirstAttempt(false);
    }
  };
  const saveForm = (data) => {
    if (data?.uid) {
      axios
        .post("/saveEducationalDetails", data)
        .then(function (response) {
          if (!response?.data?.error) {
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            // toast.error(errDesc || response?.data?.message);
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/fetchFormData", { uid: userData?.uid })
        .then(function (response) {
          setFormData(response?.data?.data);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    formData?.first_attempt == "Y" && setFirstAttempt(true);
    setBoardType(formData?.board);
  }, [formData?.first_attempt, formData?.board]);

  useEffect(() => {
    if (couseType == "UG") {
      setDefaultSchema(
        showTextForDisability
          ? UGSchemaWithDisability
          : UGSchemaWithOutDisability
      );
    } else {
      setDefaultSchema(
        showTextForDisability
          ? PGSchemaWithDisability
          : PGSchemaWithOutDisability
      );
    }
  }, [showTextForDisability]);

  return loader ? (
    <Loader />
  ) : (
    <>
      {couseType == "UG" ? (
        <>
          <h5 className="text-primary mt-4">School Details</h5>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="twelvelth_reg_mark"
                  control={control}
                  label="12th Registration Number"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.twelvelth_reg_mark}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="emis_no"
                  control={control}
                  label="EMIS no."
                  errors={errors}
                  placeholder="Eg: 3xxx5xxx6xxx"
                  defaultValue={formData?.emis_no}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="name_location"
                  control={control}
                  label="Name & District of the school studied"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.name_location}
                  mandatory="true"
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputDropdown
                  name="medium"
                  control={control}
                  label="Medium"
                  type="single"
                  options={[
                    { id: "E", name: "English" },
                    { id: "T", name: "Tamil" },
                  ]}
                  errors={errors}
                  defaultValue={formData?.medium}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputDropdown
                  name="exam"
                  control={control}
                  label="Exam"
                  type="single"
                  options={[{ id: "hsc", name: "HSC" }]}
                  errors={errors}
                  defaultValue={formData?.exam}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                {/* <FormInputDropdown
                  name="board"
                  control={control}
                  label="Board"
                  type="single"
                  options={[
                    { id: "cbsc", name: "CBSC" },
                    { id: "state_board", name: "State Board" },
                  ]}
                  errors={errors}
                  defaultValue={formData?.board}
                  mandatory="true"
                /> */}
                <div>
                  <label className="form-label">
                    Board
                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="board"
                    {...register("board")}
                    onChange={(e) => boardHandler(e.target.value)}
                  >
                    <option value="">--SELECT--</option>
                    {[
                      { id: "cbsc", name: "CBSC" },
                      { id: "state_board", name: "State Board" },
                    ].map((option) => (
                      <option
                        key={option?.id}
                        value={option?.id}
                        selected={formData?.board == option?.id}
                      >
                        {option?.name}
                      </option>
                    ))}
                  </select>
                  <p className="input-error-desc small">
                    {errors?.["board"]?.message}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputDate
                  name="month_year"
                  control={control}
                  label="Month/Year of passing"
                  type="month"
                  errors={errors}
                  defaultValue={formData?.month_year}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                {/* <FormInputRadio
                  name="first_attempt"
                  control={control}
                  label="First Attempt"
                  errors={errors}
                  defaultValue={formData?.first_attempt}
                /> */}
                <>
                  <label className="form-label">
                    First Attempt
                    <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
                  </label>
                  <div>
                    {[
                      {
                        label: "Yes",
                        value: "Y",
                      },
                      {
                        label: "No",
                        value: "N",
                      },
                    ].map((option, index) => {
                      return (
                        <div
                          className="form-check form-check-inline"
                          key={option?.value}
                        >
                          <input
                            {...register("first_attempt")}
                            className="form-check-input"
                            type="radio"
                            name="first_attempt"
                            key={index}
                            value={option?.value || ""}
                            onChange={(e) =>
                              handleFirstAttemptRadio(e?.target?.value)
                            }
                            defaultChecked={
                              formData?.first_attempt == option?.value
                                ? true
                                : false
                            }
                          />
                          <label className="form-check-label" htmlFor="college">
                            {option?.label}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <p className="input-error-desc small">
                    {errors?.["first_attempt"]?.message}
                  </p>
                </>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputRadio
                  name="laptop_received"
                  control={control}
                  label="Received Priceless Laptop from Govt"
                  errors={errors}
                  defaultValue={formData?.laptop_received}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputRadio
                  name="orphan"
                  control={control}
                  label="Orphan"
                  errors={errors}
                  defaultValue={formData?.orphan}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputRadio
                  name="first_graduate"
                  control={control}
                  label="First Graduate"
                  errors={errors}
                  defaultValue={formData?.first_graduate}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12}>
                <FormTable
                  course="UG"
                  data={formData?.mark_details}
                  control={control}
                  errors={errors}
                  board={boardType}
                  firstAttempt={firstAttempt}
                  watch={watch}
                  getValue={getValue}
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <>
          <h5 className="text-primary mt-4">For PG Students Only</h5>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="college_name"
                  control={control}
                  label="College Name"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.college_name}
                  mandatory="true"
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="university"
                  control={control}
                  label="University Name"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.university}
                  mandatory="true"
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="reg_no"
                  control={control}
                  label="Registration Number"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.reg_no}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="degree"
                  control={control}
                  label="Qualifying Degree"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.degree}
                  mandatory="true"
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputRadio
                  name="pattern"
                  control={control}
                  label="Pattern"
                  errors={errors}
                  defaultValue={formData?.pattern}
                  cOptions={[
                    {
                      label: "Semester",
                      value: "Semester",
                    },
                    {
                      label: "Non-Semester",
                      value: "Non-Semester",
                    },
                  ]}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputDate
                  name="month_year"
                  control={control}
                  label="Month/Year of passing"
                  type="month"
                  errors={errors}
                  defaultValue={formData?.month_year}
                  mandatory="true"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="date_of_admission"
                  control={control}
                  label="College previously studied with date of admission & withdrawal"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.date_of_admission}
                  mandatory="true"
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <FormInputText
                  name="reason_for_break"
                  control={control}
                  label="Reasons for break of study, if any"
                  errors={errors}
                  placeholder=""
                  defaultValue={formData?.reason_for_break}
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name="special_consideration"
                  control={control}
                  label="Any information deserving special consideration of the applicant"
                  errors={errors}
                  placeholder=" "
                  defaultValue={formData?.special_consideration}
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name="games"
                  control={control}
                  label="Has the candidate represented the University in athletics or games ? (If so produce a certificate duly signed by the Director of Physical Education of the University with full details)"
                  errors={errors}
                  placeholder=" "
                  defaultValue={formData?.games}
                  className={"capitalize"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTablePG
                  course="PG"
                  data={formData?.mark_details}
                  control={control}
                  errors={errors}
                  board={boardType}
                  firstAttempt={firstAttempt}
                  watch={watch}
                  getValue={getValue}
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      )}

      <h5 className="text-primary mt-4">Other Details</h5>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <>
              <label className="form-label">
                Differently Abled
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <div>
                {[
                  {
                    label: "Yes",
                    value: "Y",
                  },
                  {
                    label: "No",
                    value: "N",
                  },
                ].map((option, index) => {
                  return (
                    <div
                      className="form-check form-check-inline"
                      key={option?.value}
                    >
                      <input
                        {...register("differently_abled")}
                        className="form-check-input"
                        type="radio"
                        name="differently_abled"
                        id="differently_abled"
                        key={index}
                        value={option?.value || ""}
                        onChange={(e) =>
                          handleDisabilityRadio(e?.target?.value)
                        }
                        defaultChecked={
                          formData?.differently_abled == option?.value
                            ? true
                            : false
                        }
                      />
                      <label className="form-check-label" htmlFor="college">
                        {option?.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              <p className="input-error-desc small">
                {errors?.["differently_abled"]?.message}
              </p>
            </>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="type_of_differently_abled"
              control={control}
              label="Type of differently abled"
              errors={errors}
              placeholder="Hearing Impairment"
              defaultValue={formData?.type_of_differently_abled}
              disabled={!showTextForDisability}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputRadio
              name="son_of_exservicemen"
              control={control}
              label="Son/Daughter of Ex-service Man"
              errors={errors}
              defaultValue={formData?.son_of_exservicemen}
              mandatory={true}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputRadio
              name="tamil_origin_from_andaman"
              control={control}
              label="Tamil Origin from Andaman Nicobar Island"
              errors={errors}
              defaultValue={formData?.tamil_origin_from_andaman}
              mandatory={true}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputRadio
              name="distinction_sports"
              control={control}
              label="Distinction in Sports/NCC/NSS"
              errors={errors}
              defaultValue={formData?.distinction_sports}
              mandatory="true"
            />
            {/* <FormInputDropdown
              name="distinction_sports"
              control={control}
              label="Distinction in Sports/NCC/NSS"
              type="single"
              options={[
                {
                  name: "Yes",
                  value: "Y",
                },
                {
                  name: "No",
                  value: "N",
                },
              ]}
              errors={errors}
              defaultValue={formData?.board}
              mandatory="true"
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Button
              color="success"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default EducationalDetails;
