import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

export default function Loader() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress
        sx={{ position: "absolute", left: "50%", top: "50%" }}
      />
    </Backdrop>
    // <Box sx={{ display: "flex" }}>
    //   <CircularProgress
    //     sx={{ position: "absolute", left: "50%", top: "50%" }}
    //   />
    // </Box>
  );
}
