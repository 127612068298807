import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stepper: 0,
  couseType: "",
  modal: { flag: false, messge: "", error: false },
  arrayItem: [1, 2],
  countryList: [],
  stateList: [],
  cityList: [],
  collegeList: [],
  courseList: [],
  userData: {},
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      state.stepper += 1;
    },
    decrement: (state) => {
      state.stepper -= 1;
    },
    setStepper: (state, action) => {
      state.stepper = action.payload;
    },
    courseTypeReducer: (state, action) => {
      state.couseType = action.payload;
    },
    arrayItemReducer: (state, action) => ({
      ...state,
      arrayItem: [...state.arrayItem, ...action.payload],
    }),
    countryListReducer: (state, action) => {
      state.countryList = action.payload;
    },
    stateListReducer: (state, action) => {
      state.stateList = action.payload;
    },
    cityListReducer: (state, action) => {
      state.cityList = action.payload;
    },
    collegeListReducer: (state, action) => {
      state.collegeList = action.payload;
    },
    courseListReducer: (state, action) => {
      state.courseList = action.payload;
    },
    showModal: (state, action) => {
      state.modal = {
        flag: true,
        message: action.payload.msg,
        error: action.payload.error,
      };
    },
    hideModal: (state, action) => {
      state.modal = {
        flag: false,
        message: action.payload.msg,
        error: action.payload.error,
      };
    },
    reset: () => initialState,
    userDataReducer: (state, action) => ({
      ...state,
      userData: { ...state.userData, ...action.payload },
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  arrayItemReducer,
  countryListReducer,
  stateListReducer,
  cityListReducer,
  collegeListReducer,
  courseListReducer,
  courseTypeReducer,
  reset,
  setStepper,
  showModal,
  hideModal,
  userDataReducer,
} = counterSlice.actions;

export default counterSlice.reducer;
