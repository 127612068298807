import React from "react";
import { Box } from "@mui/material";
import SideNav from "../../layout/sideNav/sideNav";
import ApplicationForm from "../admission/ApplicationForm";
import { styled } from "@mui/material/styles";

function ApplicationIndex() {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <ApplicationForm />
        </Box>
      </Box>
    </>
  );
}

export default ApplicationIndex;
