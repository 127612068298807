import * as yup from "yup";

export const courseSchema = yup.object().shape({
  applying_for: yup.string().required("required"),
});
export const saveCourseSchema = yup.object().shape({
  college: yup.string().required("required"),
  course_type: yup.string().required("required"),
  courseName: yup.string().required("required"),
  fees: yup.string().required("required"),
});
export const paymentSchema = yup.object().shape({
  txn_no: yup.string().required("required"),
  amount_paid: yup.string().required("required"),
  payment_date: yup
    .string()
    .required("Transaction date is required")
    .test("len", "Invalid date format", (val) => val?.length === 10),

  remarks: yup.string().required("required"),
  payment_type: yup.string().required("required"),
});

export const personalDetailSchema = yup.object().shape({
  name: yup.string().required("required"),
  aadhar_no: yup
    .string()
    .min(12, "Only 12 digits allowed")
    .max(12, "Only 12 digits allowed")
    .required("Aadhar is required"),
  place_of_birth: yup.string().required("required"),
  // dob: yup.string().required("required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test("len", "Invalid date format", (val) => val?.length === 10),

  gender: yup.string().required("required"),
  mother_tongue: yup.string().required("required"),
  email: yup.string().required("required").email(),
  mobile: yup.string().required("required").max(10),
  mobile_whatsapp: yup.string().max(10).required("required"),
  communication_address_line_1: yup.string().required("required"),
  communication_address_line_2: yup.string().required("required"),
  communication_address_line_3: yup.string(),
  communication_city: yup.string().required("required"),
  communication_district: yup.string().required("required"),
  communication_state: yup.string().required("required"),
  communication_pincode: yup.string().required("required").max(6),
  communication_country: yup.string().required("required"),
  permanent_address_line_1: yup.string().required("required"),
  permanent_address_line_2: yup.string().required("required"),
  permanent_address_line_3: yup.string(),
  permanent_city: yup.string().required("required"),
  permanent_district: yup.string().required("required"),
  permanent_state: yup.string().required("required"),
  permanent_pincode: yup.string().required("required").max(6),
  permanent_country: yup.string().required("required"),
  community: yup.string().required("required"),
  caste: yup.string().required("required"),
  nationality: yup.string().required("required"),
  parent_father_type: yup.string().required("required"),
  father_name: yup.string().required("required"),
  father_mobile: yup.string().required("required"),
  father_occupation: yup.string().required("required"),
  father_annual_income: yup.string().required("required"),
  parent_mother_type: yup.string().required("required"),
  mother_name: yup.string().required("required"),
  mother_mobile: yup.string().required("required"),
});

export const UGSchemaWithOutDisability = yup.object().shape({
  twelvelth_reg_mark: yup.string().required("required"),
  name_location: yup.string().required("required"),
  medium: yup.string().required("required"),
  exam: yup.string().required("required"),
  board: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  first_attempt: yup.string().required("required"),
  laptop_received: yup.string().required("required"),
  orphan: yup.string().required("required"),
  first_graduate: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});
export const UGSchemaWithDisability = yup.object().shape({
  twelvelth_reg_mark: yup.string().required("required"),
  name_location: yup.string().required("required"),
  medium: yup.string().required("required"),
  exam: yup.string().required("required"),
  board: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  first_attempt: yup.string().required("required"),
  laptop_received: yup.string().required("required"),
  orphan: yup.string().required("required"),
  first_graduate: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  type_of_differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});

export const PGSchemaWithOutDisability = yup.object().shape({
  college_name: yup.string().required("required"),
  university: yup.string().required("required"),
  reg_no: yup.string().required("required"),
  degree: yup.string().required("required"),
  pattern: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  date_of_admission: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});
export const PGSchemaWithDisability = yup.object().shape({
  college_name: yup.string().required("required"),
  university: yup.string().required("required"),
  reg_no: yup.string().required("required"),
  degree: yup.string().required("required"),
  pattern: yup.string().required("required"),
  month_year: yup
    .string()
    .required("required")
    .test(
      "len",
      "Invalid date format",
      (val) => val?.split("-")[0].length == 4
    ),
  date_of_admission: yup.string().required("required"),
  differently_abled: yup.string().required("required"),
  type_of_differently_abled: yup.string().required("required"),
  son_of_exservicemen: yup.string().required("required"),
  tamil_origin_from_andaman: yup.string().required("required"),
  distinction_sports: yup.string().required("required"),
});
