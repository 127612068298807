import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "../../../layout/sideNav/sideNav";
import { DrawerHeader } from "../preview/PreviewIndex";
import { useNavigate } from "react-router-dom/dist";
import { showModal } from "../../../app/store/slice/counterSlice";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";

function CollegeDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { collegeList, courseList, couseType } = useSelector(
    (state) => state.counter
  );
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(false);

  const [courseListForCollege, setCourseListForCollege] = useState([]);
  const [formStatus, setFormStatus] = useState({});
  const [appCourse, setAppCourse] = useState({});
  const [formData, setFormData] = useState({});
  const [fee, setFee] = useState("");
  const [preview, setPreview] = useState(false);

  const methods = useForm();
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data) => saveForm({ ...data, uid: userData?.uid });

  const saveForm = (data) => {
    if (data?.uid) {
      axios
        .post("/saveCollegeDetails", data)
        .then(function (response) {
          if (!response?.data?.error) {
            getFormStatus();
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/getApplicationCourses", { uid: userData?.uid })
        .then(function (response) {
          setAppCourse(response?.data?.data);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const getFormStatus = async () => {
    let formStatus = axios.post("/getFormStatus", { uid: userData?.uid });
    let formData = axios.post("/fetchFormData", { uid: userData?.uid });
    if (userData?.uid) {
      await axios
        .all([formStatus, formData])
        .then(function (response) {
          setFormStatus(response?.[0]?.data?.data);
          setFormData(response?.[1]?.data?.data);
          if (response?.data?.data?.college_details) {
            setPreview(true);
          }
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };

  useEffect(() => {
    loadInitialData();
    getFormStatus();
  }, []);

  const handleCourse = (value) => {
    console.log(formData?.applying_for);
    const x = courseList.filter(
      (item) => item?.college_id == value && item?.type == formData?.applying_for
    );
    console.log(x);

    setCourseListForCollege(x);
  };
  const getApplicationFee = (course) => {
    if (course) {
      const x = courseList.find(
        (item) =>
          item?.college_id == getValues("college") &&
          item?.id == course &&
          item?.type == formData?.applying_for
      );
      setFee(x?.price);
    }
  };
  useEffect(() => {
    if (appCourse?.college) {
      handleCourse(appCourse?.college);
    }
  }, [appCourse?.college]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <h5 className="text-primary mt-4">College Details</h5>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  margin: "auto",
                  marginTop: "15px",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <>
                      <div className="d-flex flex-column">
                        {collegeList.map((option, index) => {
                          return (
                            <>
                              <div
                                className="form-check form-check-inline"
                                key={option?.id}
                              >
                                <input
                                  {...register("college")}
                                  className="form-check-input"
                                  type="radio"
                                  name="college"
                                  id="college"
                                  key={index}
                                  value={option?.id}
                                  onChange={(e) => handleCourse(e.target.value)}
                                  defaultChecked={
                                    appCourse?.college == option?.id
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="college"
                                >
                                  {option?.name}
                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <p className="input-error-desc small">
                        {errors?.["college"]?.message}
                      </p>
                    </>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div className="mb-3">
                      <label className="form-label">Course Selection</label>
                      <select
                        className="form-select"
                        name="course"
                        {...register("course")}
                        onChange={(e) => getApplicationFee(e.target.value)}
                      >
                        <option value="">--SELECT--</option>
                        {courseListForCollege?.map((option) => (
                          <option
                            key={option?.id}
                            value={option?.id}
                            selected={appCourse?.course == option?.id}
                          >
                            {option?.name}
                          </option>
                        ))}
                      </select>
                      <p className="input-error-desc small">
                        {errors?.["course"]?.message}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    {fee && (
                      <h6>
                        Application Fees :&nbsp;
                        <span
                          className="text-danger"
                          style={{ fontWeight: "bold" }}
                        >
                          ₹ {fee} /-
                        </span>
                      </h6>
                    )}
                  </Grid>
                  {/* {!preview && (
                    <Grid
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      container
                    >
                      <Button
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </Grid>
                  )} */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      startIcon={<FastRewindIcon />}
                      variant="contained"
                      onClick={() => navigate("/application-list")}
                    >
                      Back
                    </Button>
                    &emsp;
                    <Button
                      variant="contained"
                      // onClick={handleSubmit(onSubmit)}
                      startIcon={<PanToolAltIcon />}
                      onClick={() =>
                        navigate("/application/preview", {
                          state: {
                            collegeId: getValues("college"),
                            courseId: getValues("course"),
                          },
                        })
                      }
                    >
                      Preview
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default CollegeDetails;
