import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

export const FormInputDropdown = ({
  name,
  control,
  label,
  type,
  options,
  errors,
  defaultValue,
  mandatory,
}) => {
  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <>
          <option
            selected={defaultValue == option?.id}
            key={option?.id}
            value={option?.id}
          >
            {option?.name}
          </option>
        </>
      );
    });
  };

  return (
    // <FormControl size={"small"}>
    <Controller
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <div className="mb-3">
          <label className="form-label">
            {label}
            {mandatory && (
              <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
            )}
          </label>
          <select className="form-select" onChange={onChange}>
            <option value="">--SELECT--</option>
            {generateSingleOptions()}
          </select>
          <p className="input-error-desc small pt-1">{errors?.[name]?.message}</p>
        </div>
      )}
      control={control}
      name={name}
    />
    // </FormControl>
  );
};
