import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Grid, Paper, Avatar, TextField, Typography, Box } from "@mui/material";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth } from "../../firebase";
import axios from "axios";
import toast from "react-hot-toast";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { showModal } from "../../app/store/slice/counterSlice";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});
function SignUp() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const signUp = async (data) => {
    setLoader(true);
    await createUserWithEmailAndPassword(auth, data?.email, data?.password)
      .then((userCredential) => {
        const user = userCredential.user;
        updateProfile(user, {
          displayName: data?.name,
          // photoURL: "https://example.com/jane-q-user/profile.jpg",
        })
          .then(() => {
            setLoader(false);
            sendEmailVerification(userCredential.user);
            // toast.success("Email sent successfully");
            dispatch(
              showModal({
                msg: `Activation mail will be sent to your email id: ${data?.email}.  Kindly click the activation link to activate your email for this website.  Without activating your email, you cannot login.`,
                error: false,
              })
            );
            register(user, data?.name, data?.password);
          })
          .catch((error) => {
            setLoader(false);
            // toast.error(error?.message);
            dispatch(
              showModal({
                msg: error?.message,
                error: true,
              })
            );
          });
      })
      .catch((error) => {
        const errorMessage = error?.message;
        toast.error(errorMessage);
      });
    reset();
  };
  const register = (data, name, password) => {
    const payload = {
      accessToken: data?.accessToken,
      email: data?.email,
      uid: data?.uid,
      name: name,
      password: password,
    };
    axios
      .post("/register", payload)
      .then(function (response) {
        if (!response?.data?.error) {
          console.log(response?.data);
          auth.signOut();
          navigate("/");
        } else {
          let errDesc = "";
          Object.values(response?.data?.data).map((error, index) => {
            errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
          });
          dispatch(
            showModal({
              msg: errDesc || response?.data?.message,
              error: response?.data?.error,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.message);
      });
  };
  const paperStyle = {
    padding: 20,
    // margin: "15% 70px auto auto",
    maxWidth: "400px",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={10} style={paperStyle}>
        <Grid container>
          <Grid item align="center" m={"auto"}>
            <Avatar style={avatarStyle} sx={{ width: 56, height: 56 }}>
              A
            </Avatar>
            <h2>Sign Up</h2>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="name"
              control={control}
              label="Name"
              errors={errors}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              errors={errors}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name="password"
              control={control}
              label="Password"
              errors={errors}
              type="password"
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={handleSubmit(signUp)}
        >
          Sign Up
        </Button>

        <Typography mt={3} textAlign={"center"}>
          Already Registered ? <Link to="/">Login</Link>
        </Typography>
      </Paper>
    </Box>
  );
}

export default SignUp;
