import React, { memo, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import { FormInputRadio } from "../../../components/form-components/FormInputRadio";
import { yupResolver } from "@hookform/resolvers/yup";
import { courseSchema } from "../../../app/schema";
import { useDispatch, useSelector } from "react-redux";
import {
  courseTypeReducer,
  showModal,
} from "../../../app/store/slice/counterSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function CourseDecision({ activeStep }) {
  const dispatch = useDispatch();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const methods = useForm({
    resolver: yupResolver(courseSchema),
  });

  const {
    handleSubmit,
    reset,
    resetField,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data) =>
    saveForm({
      ...data,
      active_step: activeStep,
      uid: userData?.uid,
    });

  const saveForm = (data) => {
    if (data?.uid) {
      axios
        .post("/saveCourse", data)
        .then(function (response) {
          if (!response?.data?.error) {
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
            setSelected(true);
            dispatch(courseTypeReducer(data["applying_for"]));
            handleClose();
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
            handleClose();
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
          handleClose();
        });
    } else {
      toast.error("User ID not found");
      handleClose();
    }
  };
  const loadInitialData = async () => {
    if (userData?.uid) {
      await axios
        .post("/fetchFormData", { uid: userData?.uid })
        .then(function (response) {
          setFormData(response?.data?.data);
          if (response?.data?.data?.applying_for) {
            dispatch(courseTypeReducer(response?.data?.data["applying_for"]));
          }

          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
          setLoader(false);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setChecked(false);
    setOpen(false);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return loader ? (
    <Loader />
  ) : (
    <>
      <h5 className="text-primary mt-4">Course</h5>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <FormInputRadio
              name="applying_for"
              control={control}
              label="Applying For"
              errors={errors}
              cOptions={[
                {
                  label: "UG",
                  value: "UG",
                },
                {
                  label: "PG",
                  value: "PG",
                },
              ]}
              defaultValue={formData?.applying_for}
              mandatory="true"
            />
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Button
              disabled={formData?.applying_for || selected}
              color="success"
              variant="contained"
              onClick={handleClickOpen}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <h6 className="text-primary font-weight-bold text-center mt-4">
          Click save to proceed next
        </h6>
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            Are you sure you want to apply for the {getValues("applying_for")}
            &nbsp; program?
          </DialogContentText>
          <br />
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Please note that this is a final decision and cannot be modified once submitted. We strongly advise you to carefully verify your selection before clicking on the Agree button."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Disagree
          </Button>
          <Button
            disabled={!checked}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
}

export default CourseDecision;
