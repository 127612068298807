import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Paper, Grid, Button, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import SideNav from "../../../layout/sideNav/sideNav";
import Loader from "../../../components/loader/Loader";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { useLocation } from "react-router-dom";
import { showModal } from "../../../app/store/slice/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { DISTRICT_LIST, LOGO } from "../../../app/utlils";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PDFDownload from "./PDFDownloadUG";
import PDFDownloadUG from "./PDFDownloadUG";
import PDFDownloadPG from "./PDFDownloadPG";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function PDFView() {
  const dispatch = useDispatch();
  const { stateList, cityList, courseList, courseType } = useSelector(
    (state) => state.counter
  );
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { collegeId, courseId, flag } = location.state;
  const applicationId = location.state?.applicationId;
  const uid = location.state?.uid;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);
  const [consent, setConsent] = useState(false);
  const [showConsent, setShowConsent] = useState(true);
  const [applicationStatus, setApplicationStatus] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [formData, setFormData] = useState({});
  const parseDate = (date) => {
    var d = new Date(date);

    var datestring =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear();
    return datestring;
  };

  const loadInitialData = () => {
    const userId = userData?.role == 3 ? userData?.uid : uid;
    if (userData?.uid) {
      let formData = axios.post("/fetchFormData", {
        uid: userId,
      });
      let formStatus = axios.post("/getFormStatus", {
        uid: userId,
      });
      axios
        .all([formData, formStatus])
        .then(function (response) {
          setFormData(response?.[0]?.data?.data);
          const appStatus = response?.[1]?.data?.data?.status;
          appStatus == "P" && setApplicationStatus(true);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const sendSuccessMail = (appId) => {
    if (userData?.uid) {
      const payload = { uid: userData?.uid, applicationId: appId };
      axios
        .post("/sendSuccessMail", payload)
        .then(function (response) {})
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const finalSubmit = (data) => {
    if (userData?.uid) {
      const payload = {
        ...data,
        course: courseId,
        college: collegeId,
        consent: true,
      };
      axios
        .post("/saveCollegeDetails", payload)
        .then(function (response) {
          if (!response?.data?.error) {
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
            setShowConsent(false);
            sendSuccessMail(response?.data?.data);
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
          setLoader(false);
        })
        .catch(function (err) {
          dispatch(
            showModal({
              msg: err?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };
  function handleCheckChange(e) {
    setConsent(e.target.checked);
  }
  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <h5 className="text-primary mt-4">Application Preview</h5>
              <Paper
                elevation={3}
                sx={{
                  px: 10,
                  py: 3,
                  margin: "auto",
                  marginTop: "15px",
                  flexGrow: 1,
                }}
              >
                {formData?.applying_for == "UG" ? (
                  <PDFDownloadUG
                    applicationId={applicationId}
                    reference={componentRef}
                    formData={formData}
                    data={{
                      stateList,
                      cityList,
                      courseList,
                      collegeId,
                      courseId,
                    }}
                    handleCheckChange={handleCheckChange}
                  />
                ) : (
                  <PDFDownloadPG
                    applicationId={applicationId}
                    reference={componentRef}
                    formData={formData}
                    data={{
                      stateList,
                      cityList,
                      courseList,
                      collegeId,
                      courseId,
                    }}
                    handleCheckChange={handleCheckChange}
                  />
                )}

                {/* {!!!applicationId && ( */}
              

                {/* )} */}

                {!!!applicationId ? (
                  <div className="row mt-4">
                    <div className="col d-flex justify-content-center">
                      <Button
                        color="inherit"
                        variant="contained"
                        onClick={() => navigate(-1)}
                        startIcon={<FastRewindIcon />}
                      >
                        Back to application
                      </Button>{" "}
                      &emsp;
                      <Button
                        disabled={consent ? false : true}
                        color="success"
                        variant="contained"
                        onClick={() => finalSubmit({ uid: userData?.uid })}
                        startIcon={<SaveIcon />}
                      >
                        Submit the application
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <div className="col d-flex justify-content-center">
                      <Button
                        color="inherit"
                        variant="contained"
                        onClick={() => navigate(-1)}
                        startIcon={<FastRewindIcon />}
                      >
                        Back
                      </Button>{" "}
                      &emsp;
                      <Button
                        variant="contained"
                        onClick={() => handlePrint()}
                        startIcon={<SaveIcon />}
                      >
                        Print
                      </Button>
                    </div>
                  </div>
                )}
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PDFView;
