import React from "react";
import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";

const defaultOptions = [
  {
    label: "Yes",
    value: "Y",
  },
  {
    label: "No",
    value: "N",
  },
];

export const FormInputRadio = ({
  name,
  control,
  label,
  errors,
  cOptions,
  defaultValue,
  mandatory,
}) => {
  const options = cOptions ? cOptions : defaultOptions;
  return (
    <FormControl component="fieldset">
      <label className="form-label">
        {label}
        {mandatory && (
          <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
        )}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <div>
                {options.map((option, index) => (
                  <div className="form-check form-check-inline" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={name}
                      id={name}
                      value={option?.value}
                      onChange={onChange}
                      defaultChecked={
                        defaultValue == option?.value ? true : false
                      }
                    />
                    <label className="form-check-label" htmlFor={name}>
                      {option?.label}
                    </label>
                  </div>
                ))}
              </div>
              <p className="input-error-desc small">
                {errors?.[name]?.message}
              </p>
            </>
          );
        }}
      />
    </FormControl>
  );
};
