import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Grid, Paper, Avatar, TextField, Typography, Box } from "@mui/material";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth } from "../../firebase";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import axios from "axios";
import ShowSnackbar from "../../components/snackbar/snackbar";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { userDataReducer } from "../../app/store/slice/counterSlice";
import { LOGO } from "../../app/utlils";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

function ForgetPassword() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const resetPassword = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data?.email);
      toast.success("Password reset email sent");
      reset();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const paperStyle = {
    padding: 20,
    maxWidth: "400px",
    margin: "10%",
  };
  const btnstyle = { margin: "8px 0" };
  return (
    <Box
      className="login-page"
      sx={{
        // backgroundColor: "#90a4ae",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={10} style={paperStyle} className="bg-light">
        <Grid container>
          <Grid item align="center" m={"auto"}>
            <img src={LOGO} alt="" width={100} />
          </Grid>

          <Grid item xs={12}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              errors={errors}
              type="text"
              autofocus={true}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={handleSubmit(resetPassword)}
        >
          Reset
        </Button>

        <Typography mt={3} textAlign={"center"}>
          <Link to="/login">Login</Link>
        </Typography>
      </Paper>
    </Box>
  );
}

export default ForgetPassword;
