import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, Grid, Button, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import SideNav from "../../../layout/sideNav/sideNav";
import Loader from "../../../components/loader/Loader";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { useLocation } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { showModal } from "../../../app/store/slice/counterSlice";
import { useDispatch } from "react-redux";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function PreviewIndex() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { collegeId, courseId } = location.state;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);
  const [consent, setConsent] = useState(false);
  const [showConsent, setShowConsent] = useState(true);
  const [applicationStatus, setApplicationStatus] = useState(false);

  const [formData, setFormData] = useState({});
  const VARIANT = "body1";
  const VALUE = "body1";
  const previewArray = [
    {
      name: "Personal Details",
      data: [
        {
          label: "Name",
          value: formData?.name,
          lg: 4,
        },
        {
          label: "Initial",
          value: formData?.initial,
          lg: 4,
        },
        {
          label: "Aadhar Number",
          value: formData?.aadhar_no,
          lg: 4,
        },
        {
          label: "Date of Birth",
          value: formData?.dob,
          lg: 4,
        },
        {
          label: "Email",
          value: formData?.email,
          lg: 4,
        },
        {
          label: "Mobile Number",
          value: formData?.mobile,
          lg: 4,
        },
        {
          label: "Address",
          value: formData?.address,
          lg: 4,
        },
        {
          label: "City",
          value: formData?.city,
          lg: 4,
        },
        {
          label: "State/Province",
          value: formData?.state,
          lg: 4,
        },
        {
          label: "ZIP/Postal Code",
          value: formData?.pincode,
          lg: 4,
        },
        {
          label: "Country",
          value: formData?.country,
          lg: 4,
        },
      ],
    },
    {
      name: "Family Details",
      data: [
        {
          label: "Parent / Guardian",
          value: formData?.name,
          lg: 4,
        },
        {
          label: "Father Name",
          value: formData?.initial,
          lg: 4,
        },
        {
          label: "Father Mobile",
          value: formData?.aadhar_no,
          lg: 4,
        },
        {
          label: "Father Occupation",
          value: formData?.dob,
          lg: 4,
        },
        {
          label: "Annual Income",
          value: formData?.email,
          lg: 4,
        },
        {
          label: "Mother Name",
          value: formData?.mobile,
          lg: 4,
        },
        {
          label: "Mother Mobile",
          value: formData?.address,
          lg: 4,
        },
      ],
    },
    {
      name: "Other Details",
      data: [
        {
          label: "Differently Abled",
          value: formData?.differently_abled,
          lg: 3,
        },
        {
          label: "Type of differently abled",
          value: formData?.type_of_differently_abled,
          lg: 3,
        },
        {
          label: "Son/Daughter of Ex-service Man",
          value: formData?.son_of_exservicemen,
          lg: 3,
        },
        {
          label: "Tamil Origin from Andaman Nicobar Island",
          value: formData?.tamil_origin_from_andaman,
          lg: 3,
        },
      ],
    },
    {
      name: "School Details",
      data: [
        {
          label: "EMIS no.",
          value: formData?.emis_no,
          lg: 4,
        },
        {
          label: "Medium",
          value: formData?.medium,
          lg: 4,
        },
        {
          label: "Received Laptop",
          value: formData?.laptop_received,
          lg: 4,
        },
        {
          label: "School Name",
          value: formData?.school,
          lg: 4,
        },
        {
          label: "Exam",
          value: formData?.exam,
          lg: 4,
        },
        {
          label: "Board",
          value: formData?.board,
          lg: 4,
        },
        {
          label: "First Attempt",
          value: formData?.first_attempt,
          lg: 4,
        },
        {
          label: "Month/Year of passing",
          value: formData?.month_year,
          lg: 4,
        },
      ],
    },
    {
      name: "College Details",
      data: [
        {
          label: "College Selection",
          value: formData?.college,
          lg: 4,
        },
        {
          label: "Course Selection",
          value: formData?.course,
          lg: 4,
        },
      ],
    },
  ];
  const uploadArray = [
    { name: "Photo Of Student", column: "student_photo" },
    { name: "Signature", column: "signature" },
    { name: "Parent Signature", column: "parents_signature" },
    { name: "Transfer Certificate 1st Page", column: "tc_first_page" },
    { name: "Transfer Certificate 2st Page", column: "tc_secont_page" },
    { name: "Mark Sheet", column: "marksheet" },
    { name: "Community Certificate", column: "community_certificate" },
    { name: "Aadhar Card", column: "aadhar" },
    { name: "NAD", column: "nad" },
  ];

  const loadInitialData = () => {
    if (userData?.uid) {
      let formData = axios.post("/fetchFormData", { uid: userData?.uid });
      let formStatus = axios.post("/getFormStatus", { uid: userData?.uid });
      axios
        .all([formData, formStatus])
        .then(function (response) {
          setFormData(response?.[0]?.data?.data);
          const appStatus = response?.[1]?.data?.data?.status;
          appStatus == "P" && setApplicationStatus(true);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const sendSuccessMail = (appId) => {
    if (userData?.uid) {
      axios
        .post("/sendSuccessMail", { uid: userData?.uid, applicationId: appId })
        .then(function (response) {})
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const finalSubmit = (data) => {
    if (userData?.uid) {
      const payload = {
        ...data,
        course: courseId,
        college: collegeId,
        consent: true,
      };
      axios
        .post("/saveCollegeDetails", payload)
        .then(function (response) {
          if (!response?.data?.error) {
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
            setShowConsent(false);
            sendSuccessMail(response?.data?.data);
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
          setLoader(false);
        })
        .catch(function (err) {
          dispatch(
            showModal({
              msg: err?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };
  function handleCheckChange(e) {
    setConsent(e.target.checked);
  }
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <h5 className="text-primary mt-4">Application Preview</h5>
              <Paper
                elevation={3}
                sx={{
                  px: 10,
                  py: 3,
                  margin: "auto",
                  marginTop: "15px",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
                  {previewArray?.map((item, index) => (
                    <>
                      <Grid item xs={12}>
                        <h6 className="text-primary fw-bold text-decoration-underline mb-3">
                          {item?.name}
                        </h6>
                      </Grid>
                      {item?.data.map((cell, index) => {
                        return (
                          <Grid item xs={12} md={6} lg={cell?.lg} xl={cell?.lg}>
                            <div className="d-flex">
                              <Typography color={"GrayText"} variant={VARIANT}>
                                {cell?.label} :
                              </Typography>
                              <Typography variant={VALUE} pl={2}>
                                {cell?.value}
                              </Typography>
                            </div>
                          </Grid>
                        );
                      })}
                      <Grid item xs={12} my={1.5}>
                        <Divider variant="middle" sx={{ border: 0.5 }} />
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12}>
                    <h6 className="text-primary fw-bold text-decoration-underline mb-3">
                      Documents
                    </h6>
                  </Grid>
                  {uploadArray?.map((elt) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={4}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <img
                          src={`${formData?.column}`}
                          alt=""
                          className="mt-2 w-75 px-5"
                        />
                        <Typography
                          variant={VALUE}
                          pl={2}
                          sx={{ textAlign: "center", paddingTop: 1 }}
                        >
                          {elt?.name}
                        </Typography>
                      </Grid>
                    );
                  })}
                  {showConsent && (
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <h6>
                        Consent <span className="text-danger">*</span>
                      </h6>
                      <div className="form-check">
                        <input
                          name="consent "
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={handleCheckChange}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                          style={{ color: "red" }}
                        >
                          I assure all the details are filled correctly and
                          understand any incorrect detailed application will be
                          rejected.
                        </label>
                      </div>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    container
                  >
                    {" "}
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => navigate(-1)}
                      startIcon={<FastRewindIcon />}
                    >
                      Back to application
                    </Button>{" "}
                    &emsp;
                    <Button
                      disabled={consent ? false : true}
                      color="success"
                      variant="contained"
                      onClick={() => finalSubmit({ uid: userData?.uid })}
                      startIcon={<SaveIcon />}
                    >
                      Submit the application
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PreviewIndex;
