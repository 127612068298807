import React, { useEffect, useState } from "react";

import { Alert, Box, Button, Stack } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "../../../layout/sideNav/sideNav";
import { DrawerHeader } from "../preview/PreviewIndex";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom/dist";
import { setStepper } from "../../../app/store/slice/counterSlice";
import { styled } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const useStyles = styled({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
        margin: "auto",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
});
function ApplicationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { collegeList, courseList } = useSelector((state) => state.counter);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({});
  const [canApply, setCanApply] = useState(false);
  const [sectionMsg, setSectionMsg] = useState("");
  const [dataTables, setDataTables] = useState([]);

  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/getFormStatus", { uid: userData?.uid })
        .then(function (response) {
          setFormData(response?.data?.data);
          const formData = response?.data?.data;
          checkEligibility(formData);
          // setCanApply(true);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const checkEligibility = async (data) => {
    if (data?.course_status) {
      if (data?.personal_details) {
        if (data?.other_details) {
          if (data?.family_details) {
            if (data?.school_details) {
              setCanApply(true);
            } else {
              setSectionMsg("Educational Details");
              dispatch(setStepper(2));
              setCanApply(false);
            }
          } else {
            setSectionMsg("Family Details");
            dispatch(setStepper(1));
            setCanApply(false);
          }
        } else {
          setSectionMsg("Other Details");
          dispatch(setStepper(1));
          setCanApply(false);
        }
      } else {
        setSectionMsg("Personal Details");
        dispatch(setStepper(1));
        setCanApply(false);
      }
    } else {
      setSectionMsg("Course Applying For");
      dispatch(setStepper(0));
      setCanApply(false);
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "application",
      label: "Application No.",
      options: {
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "college",
      label: "College",
    },
    {
      name: "courseName",
      label: "Course",
    },
    {
      name: "fee",
      label: "Fee",
    },
    {
      name: "status",
      label: "Application Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = dataTables[rowIndex].status;
          let label;
          let classType;
          if (status == "I") {
            label = "Initiated";
            classType = "text-primary";
          } else if (status == "S") {
            label = "Submitted";
            classType = "text-success";
          } else if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "X") {
            label = "Error";
            classType = "text-danger";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "Payment Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = dataTables[rowIndex].payment_status;
          let label;
          let classType;
          if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "S") {
            label = "Paid";
            classType = "text-success";
          } else {
            label = "Awaiting confirmation";
            classType = "text-warning";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
    {
      name: "payment",
      label: "Payment",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              <Button
                aria-label="edit"
                onClick={() =>
                  navigate("/payment", {
                    state: {
                      applicationId: dataTables[rowIndex].application,
                      fees: dataTables[rowIndex].fee,
                      collegeId: dataTables[rowIndex].collegeId,
                      courseId: dataTables[rowIndex].courseId,
                    },
                  })
                }
              >
                <PaymentIcon fontSize="large" />
              </Button>
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
    {
      name: "Print",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              {dataTables[rowIndex].status == "S" ? (
                <Button
                  aria-label="edit"
                  onClick={() =>
                    navigate("/application/preview", {
                      state: {
                        collegeId: dataTables[rowIndex].collegeId,
                        courseId: dataTables[rowIndex].courseId,
                        applicationId: dataTables[rowIndex].application,
                      },
                    })
                  }
                >
                  <LocalPrintshopIcon fontSize="large" color="secondary" />
                </Button>
              ) : (
                "-"
              )}
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];

  useEffect(() => {
    const tableRows = formData?.applications?.map((row) => {
      const college = collegeList.find((item) => row?.college_id == item?.id);
      const course = courseList.find((item) => row?.course_id == item?.id);

      return {
        application: row?.application_id,
        college: college?.name,
        collegeId: college?.id,
        courseId: course?.id,
        courseName: course?.name,
        fee: row?.fees,
        status: row?.status,
        payment_status: row?.payment_status,
      };
    });
    setDataTables(tableRows);
  }, [formData?.applications]);

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    customSearch: false,
    print: false,
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <h5 className="text-primary ">Application Details</h5>
                {canApply && (
                  <Button
                    size="large"
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="mb-3"
                    color="success"
                    onClick={() => navigate("/new-application")}
                  >
                    Apply Application
                  </Button>
                )}
              </Stack>
              {!canApply ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="warning">
                    <h6>
                      Please complete the <b>{sectionMsg}</b> in the &nbsp;
                      <Link to="/profile">
                        <b>Profile</b>
                      </Link>
                      {"  "}
                      section in order to proceed with the application.
                    </h6>
                  </Alert>
                </Stack>
              ) : dataTables?.length > 0 ? (
                <MUIDataTable
                  data={dataTables}
                  columns={columns}
                  options={options}
                />
              ) : (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="info">
                    <h6>
                      No Application Submitted Yet, Please click on&nbsp;
                      <Link to="/new-application">
                        <b>Apply Application</b>
                      </Link>{" "}
                      to proceed.
                    </h6>
                  </Alert>
                </Stack>
              )}

              {/* {data.length > 0 ? (
                <MUIDataTable
                  title={"Employee List"}
                  data={data}
                  columns={columns}
                  options={options}
                />
              ) : (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="info">
                    <h6>
                      No Application Submitted Yet, Please click on
                      <Link to="/new-application">
                        <b>Apply Application</b>
                      </Link>{" "}
                      to proceed.
                    </h6>
                  </Alert>
                </Stack>
              )} */}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ApplicationList;
