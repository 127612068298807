import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import axios from "axios";
import { FormInputFile } from "../../../components/form-components/FormInputFile";
import SaveIcon from "@mui/icons-material/Save";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import toast from "react-hot-toast";
import { FormCInputFile } from "../../../components/form-components/FormCInputFile";
import Loader from "../../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { showModal } from "../../../app/store/slice/counterSlice";

function UploadDoc({ activeStep, setUploadCompleted }) {
  const dispatch = useDispatch();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [showBtn, setShowBtn] = useState(true);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const onSubmit = (data) =>
    save({ ...data, active_step: activeStep, uid: userData?.uid });

  const save = (data) => {
    if (data?.uid) {
      const formData = new FormData();
      formData.append("student_photo", data?.student_photo);
      formData.append("uid", userData?.uid);
      axios
        .post("/documentUpload", formData)
        .then(function (response) {
          if (!response?.data?.error) {
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data)?.map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            // toast.error(errDesc || response?.data?.message);
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/fetchFormData", { uid: userData?.uid })
        .then(function (response) {
          setFormData(response?.data?.data?.attachments);
          const formData = response?.data?.data;
          setUploadCompleted(true);
          // if (
          //   formData?.student_photo &&
          //   formData?.signature &&
          //   formData?.parents_signature &&
          //   formData?.tc_first_page &&
          //   formData?.tc_secont_page &&
          //   formData?.marksheet &&
          //   formData?.community_certificate &&
          //   formData?.aadhar &&
          //   formData?.nad
          // ) {
          //   setUploadCompleted(true);
          // } else {
          //   setUploadCompleted(false);
          // }
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  // useEffect(() => {
  //   if (
  //     formData?.student_photo &&
  //     formData?.signature &&
  //     formData?.parents_signature &&
  //     formData?.tc_first_page &&
  //     formData?.tc_secont_page &&
  //     formData?.marksheet &&
  //     formData?.community_certificate &&
  //     formData?.aadhar &&
  //     formData?.nad
  //   ) {
  //     setUploadCompleted(true);
  //     console.log(true);
  //   }
  //   console.log(false);
  // }, []);
  return loader ? (
    <Loader />
  ) : (
    <>
      <h5 className="text-primary mt-4">Document Upload</h5>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
          {/* <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputFile
              name="student_photo"
              control={control}
              label="Student Photo"
              setValue={setValue}
              type="text"
            />
          </Grid> */}
          <Grid item xs={12}>
            <ul className="text-primary">
              <li>Please upload only jpg, jpeg, png.</li>
              <li>File size shall not exceed 200kb</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="student_photo"
              control={control}
              label="Student Photo"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "student_photo")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="signature"
              control={control}
              label="Signature"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "signature")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="parents_signature"
              control={control}
              label="Parent Signature"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "parents_signature")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="tc_first_page"
              control={control}
              label="TC First Page"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "tc_first_page")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="tc_secont_page"
              control={control}
              label="TC Second Page"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "tc_secont_page")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="marksheet"
              control={control}
              label="Marksheet"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "marksheet")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="community_certificate"
              control={control}
              label="Community Certificate"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "community_certificate")
                  ?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="aadhar"
              control={control}
              label="Aadhar"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={
                formData?.find((item) => item.type == "aadhar")?.file
              }
              loadInitialData={loadInitialData}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormCInputFile
              name="nad"
              control={control}
              label="NAD"
              setValue={setValue}
              type="text"
              uid={userData?.uid}
              defaultValue={formData?.find((item) => item.type == "nad")?.file}
              loadInitialData={loadInitialData}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Button
              disabled={!showBtn}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
}

export default UploadDoc;
