// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// OLD FIREBASE CREDENTIALS
// const firebaseConfig = {
//   apiKey: "AIzaSyDatZouY9UeDo4Stii7pD55qCxgJvSozjw",
//   authDomain: "madurai-college.firebaseapp.com",
//   projectId: "madurai-college",
//   storageBucket: "madurai-college.appspot.com",
//   messagingSenderId: "186959827316",
//   appId: "1:186959827316:web:725aa0754e8624b4e95c21",
//   measurementId: "G-XB99K5Q7QR"
// };

// LATEST FIREBASE CREDENTIALS
const firebaseConfig = {
  apiKey: "AIzaSyA3jTnegB6G0SeoXWtcgPtmzwkh0otm5k0",
  authDomain: "saurashtra-web.firebaseapp.com",
  projectId: "saurashtra-web",
  storageBucket: "saurashtra-web.appspot.com",
  messagingSenderId: "152928197466",
  appId: "1:152928197466:web:c13f02d8ea3137ea0ef163",
  measurementId: "G-HG8655FFLE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;