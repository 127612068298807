import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Grid, Paper, Avatar, TextField, Typography, Box } from "@mui/material";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import ShowSnackbar from "../../components/snackbar/snackbar";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { showModal, userDataReducer } from "../../app/store/slice/counterSlice";
import { LOGO } from "../../app/utlils";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});

function LoginScreen() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const login = (data) => {
    try {
      signInWithEmailAndPassword(auth, data?.email, data?.password)
        .then((response) => {
          const user = response?.user;
          if (user?.emailVerified) {
            const authUserData = {
              isLoggedIn: true,
              accessToken: user.accessToken,
              email: user.email,
              emailVerified: user.emailVerified,
              refreshToken: response._tokenResponse.refreshToken,
              uid: user.uid,
              name: user?.displayName,
            };

            const payload = {
              accessToken: user.accessToken,
              email: user.email,
              emailVerified: user.emailVerified,
              password: data?.password,
            };
            axios
              .post("/login", payload)
              .then(function (response) {
                const res = response?.data;
                if (!res?.error) {
                  // axios.defaults.headers.common[
                  //   "Authorization"
                  // ] = `Bearer ${res?.data?.access_token}`;
                  sessionStorage.setItem(
                    "userData",
                    JSON.stringify({
                      ...authUserData,
                      token: res?.data?.access_token,
                      role: res?.data?.role,
                    })
                  );
                  sessionStorage.setItem("stepper", 0);
                  navigate("/dashboard");
                  toast.success("Logged in successfully");
                } else {
                  dispatch(
                    showModal({
                      msg: res?.message,
                      error: true,
                    })
                  );
                }
              })
              .catch(function (error) {
                dispatch(
                  showModal({
                    msg: error?.message,
                    error: true,
                  })
                );
              });
          } else {
            dispatch(
              showModal({
                msg: `Activation mail will be sent to your email id: ${user?.email}. Kindly click the activation link, in the email, to activate your email for this website. Without activating your email, you cannot login.   Check spam folder if you could not find the email in your inbox.`,
                error: true,
              })
            );
            // toast.error("Email not verified");
          }
        })
        .catch((err) => {
          if (err?.message == "Firebase: Error (auth/user-not-found).") {
            dispatch(
              showModal({
                msg: "No user found.",
                error: true,
              })
            );
          } else {
            toast.error(err?.message);
          }
        });
    } catch (err) {
      if (
        err?.message == "Firebase: Error (auth/wrong-password)." ||
        err?.message == "Firebase: Error (auth/user-not-found)."
      ) {
        dispatch(
          showModal({
            msg: "Email or Password is incorrect.",
            error: true,
          })
        );
      } else {
        toast.error(err?.message);
      }
    }
  };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     handleSubmit(onSubmit)();
  //   }
  // };

  const paperStyle = {
    padding: 20,
    maxWidth: "400px",
    margin: "10%",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };
  return (
    <Box
      className="login-page"
      sx={{
        // backgroundColor: "#90a4ae",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={10} style={paperStyle} className="bg-light">
        <form onSubmit={handleSubmit(login)}>
          <Grid container>
            <Grid item align="center" m={"auto"}>
              <img src={LOGO} alt="" width={100} />
              <h3>Sourashtra College</h3>
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormInputText
                name="email"
                control={control}
                label="Email"
                errors={errors}
                type="text"
                autofocus={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="password"
                control={control}
                label="Password"
                errors={errors}
                type="password"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            // onClick={handleSubmit(login)}
          >
            Login
          </Button>
          <Typography mt={3} textAlign={"center"}>
            Not Registered? <Link to="/signup">Sign Up</Link>
          </Typography>
          <Typography mt={3} textAlign={"center"}>
            <Link to="/forget-password">Forget Password ?</Link>
          </Typography>
        </form>
      </Paper>
    </Box>
  );
}

export default LoginScreen;
