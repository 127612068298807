import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const TableInputText = ({
  name,
  control,
  type,
  defaultValue,
  maxLength,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue  || ""}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <input
          type={type}
          name={name}
          required
          className={"form-control"}
          onChange={onChange}
          defaultValue={value || defaultValue || ""}
          maxLength={maxLength}
          disabled={disabled | false}
        />
      )}
    />
  );
};
