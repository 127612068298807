import React, { useState } from "react";
import { TableInputText } from "./TableInputText";
import { TableInputDate } from "./TableInputDate";
import { getValue } from "@testing-library/user-event/dist/utils";

function FormTablePG({
  course,
  data,
  control,
  errors,
  board,
  firstAttempt,
  watch,
  getValue,
}) {
  let total = 0;
  let maximumTotal = 0;
  data?.map((item) => {
    total = parseInt(total) + parseInt(item?.marks);
    maximumTotal = parseInt(maximumTotal) + parseInt(item?.maximum);
  });
  const [markTotal, setMarkTotal] = useState(total);
  const [maxTotal, setMaxTotal] = useState(maximumTotal);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const mark1 = value?.mark_1 | 0;
      const mark2 = value?.mark_2 | 0;
      const mark3 = value?.mark_3 | 0;
      const mark4 = value?.mark_4 | 0;
      const mark5 = value?.mark_5 | 0;
      const mark6 = value?.mark_6 | 0;
      setMarkTotal(+mark1 + +mark2 + +mark3 + +mark4 + +mark5 + +mark6);

      const maximum1 = value?.maximum_1 | 0;
      const maximum2 = value?.maximum_2 | 0;
      const maximum3 = value?.maximum_3 | 0;
      const maximum4 = value?.maximum_4 | 0;
      const maximum5 = value?.maximum_5 | 0;
      const maximum6 = value?.maximum_6 | 0;
      setMaxTotal(
        +maximum1 + +maximum2 + +maximum3 + +maximum4 + +maximum5 + +maximum6
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <h5 className="text-primary text-decoration-underline">
          {"UG Details"}
        </h5>

        <p className="text-center" style={{ color: "red", fontWeight: 600 }}>
          <i>* All Fields are required in the table (Except Optional in PART-III 6th row)</i>
        </p>
      </div>
      <table className="mark-table table table-bordered">
        <thead>
          <tr>
            <th>Part</th>
            <th>SUBJECT</th>
            <th>MARKS</th>
            <th>MAXIMUM</th>
            <th>CLASS OBTAINED</th>
            <th>PERCENTAGE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "10%" }}>
              <p className="px-1">PART I</p>
              <TableInputText
                name="part_1"
                control={control}
                defaultValue={"PART I"}
                type="hidden"
              />
            </td>
            <td>
              <TableInputText
                name="subject_1"
                control={control}
                defaultValue={"Language/Alternative subject"}
              />
            </td>
            <td className>
              <TableInputText
                name="mark_1"
                control={control}
                defaultValue={data?.[0]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_1"
                control={control}
                defaultValue={data?.[0]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_1"
                control={control}
                type="text"
                defaultValue={data?.[0]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_1"
                control={control}
                type="number"
                defaultValue={data?.[0]?.percentage}
              />
            </td>
          </tr>
          <tr>
            <td>
              <p className="px-1">PART II</p>
              <TableInputText
                name="part_2"
                control={control}
                defaultValue={"PART II"}
                type="hidden"
              />
            </td>
            <td>
              <TableInputText
                name="subject_2"
                control={control}
                defaultValue={"English"}
              />
            </td>
            <td className>
              <TableInputText
                name="mark_2"
                control={control}
                defaultValue={data?.[1]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_2"
                control={control}
                defaultValue={data?.[1]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_2"
                control={control}
                type="text"
                defaultValue={data?.[1]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_2"
                control={control}
                type="number"
                defaultValue={data?.[1]?.percentage}
              />
            </td>
          </tr>
          <tr>
            <td rowSpan={4}>
              <p className="px-1">PART III</p>
              <TableInputText
                name="part_3"
                control={control}
                defaultValue={"PART III"}
                type="hidden"
              />
              <TableInputText
                name="part_4"
                control={control}
                defaultValue={"PART III"}
                type="hidden"
              />
              <TableInputText
                name="part_5"
                control={control}
                defaultValue={"PART III"}
                type="hidden"
              />
              <TableInputText
                name="part_6"
                control={control}
                defaultValue={"PART III"}
                type="hidden"
              />
            </td>
            <td>
              <TableInputText
                name="subject_3"
                control={control}
                defaultValue={"Major"}
              />
            </td>
            <td className>
              <TableInputText
                name="mark_3"
                control={control}
                defaultValue={data?.[2]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_3"
                control={control}
                defaultValue={data?.[2]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_3"
                control={control}
                type="text"
                defaultValue={data?.[2]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_3"
                control={control}
                type="number"
                defaultValue={data?.[2]?.percentage}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TableInputText
                name="subject_4"
                control={control}
                defaultValue={"Ancillary I"}
              />
            </td>
            <td>
              <TableInputText
                name="mark_4"
                control={control}
                defaultValue={data?.[3]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_4"
                control={control}
                defaultValue={data?.[3]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_4"
                control={control}
                type="text"
                defaultValue={data?.[3]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_4"
                control={control}
                type="number"
                defaultValue={data?.[3]?.percentage}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TableInputText
                name="subject_5"
                control={control}
                defaultValue={"Ancillary II"}
              />
            </td>
            <td>
              <TableInputText
                name="mark_5"
                control={control}
                defaultValue={data?.[4]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_5"
                control={control}
                defaultValue={data?.[4]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_5"
                control={control}
                type="text"
                defaultValue={data?.[4]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_5"
                control={control}
                type="number"
                defaultValue={data?.[4]?.percentage}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TableInputText
                name="subject_6"
                control={control}
                defaultValue={"Optional"}
              />
            </td>
            <td>
              <TableInputText
                name="mark_6"
                control={control}
                defaultValue={data?.[5]?.marks}
              />
            </td>
            <td>
              <TableInputText
                name="maximum_6"
                control={control}
                defaultValue={data?.[5]?.maximum}
              />
            </td>
            <td>
              <TableInputText
                name="class_6"
                control={control}
                type="text"
                defaultValue={data?.[5]?.class}
              />
            </td>
            <td>
              <TableInputText
                name="percentage_6"
                control={control}
                type="number"
                defaultValue={data?.[5]?.percentage}
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>TOTAL</th>

            <th>{markTotal}</th>
            <th>{maxTotal}</th>
            <th colSpan={3}></th>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default FormTablePG;
