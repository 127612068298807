import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormInputDate = ({
  name,
  control,
  label,
  errors,
  type,
  defaultValue,
  mandatory,
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <div className="mb-3">
          <label className="form-label">
            {label}
            {mandatory && (
              <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
            )}
          </label>
          <input
            type={type}
            className="form-control"
            placeholder={`Enter ${label}`}
            onChange={onChange}
            defaultValue={defaultValue || ""}
          />
          <p className="input-error-desc small">{errors?.[name]?.message}</p>
        </div>
      )}
    />
  );
};
