import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormInputText = ({
  name,
  control,
  label,
  errors,
  type,
  placeholder,
  defaultValue,
  mandatory,
  maxLength,
  disabled,
  className,
  autocapitalize,
  autoFocus,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <div className="mb-3">
          <label className="form-label">
            {label}
            {mandatory && (
              <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
            )}
          </label>
          <input
            autoCapitalize={autocapitalize}
            type={type}
            className={`form-control ${className}`}
            placeholder={placeholder ? placeholder : `Enter ${label}`}
            onChange={onChange}
            // value={value || defaultValue}
            defaultValue={value || defaultValue}
            maxLength={maxLength}
            disabled={disabled | false}
            required={!!!mandatory}
            autoFocus={autoFocus}
          />
          <p className="input-error-desc small pt-1">
            {errors?.[name]?.message}
          </p>
        </div>
      )}
    />
  );
};
