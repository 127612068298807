import React, { useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom/dist";
import { styled } from "@mui/material";
import Loader from "../../components/loader/Loader";
import SideNav from "../../layout/sideNav/sideNav";
import { DrawerHeader } from "../admission/preview/PreviewIndex";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { FormInputText } from "../../components/form-components/FormInputText";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveCourseSchema } from "../../app/schema";
import { showModal } from "../../app/store/slice/counterSlice";

// const useStyles = styled({
//   root: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     border: 0,
//     borderRadius: 3,
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//     color: "white",
//     height: 48,
//     padding: "0 30px",
//   },
//   columnHeader: {
//     "& span": {
//       "& Button": {
//         fontWeight: "600",
//         fontFamily: "Poppins, sans-serif",
//         margin: "auto",
//       },
//     },
//   },
//   arrangeCenter: {
//     textAlign: "center",
//   },
//   centeredHeader: {
//     "& span": {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     },
//   },
//   rowFont: {
//     " & div:nth-child(2)": {
//       fontFamily: "Poppins, sans-serif",
//       fontSize: "12px",
//       fontWeight: "500",
//     },
//   },
// });
function CourseFee() {
  const dispatch = useDispatch();

  //   const classes = useStyles();
  const methods = useForm({
    resolver: yupResolver(saveCourseSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    resetField,
    getValues,
    register,
    formState: { errors },
  } = methods;
  const query = () => {
    setLoader(true);
    axios
      .get(`/getAllCourses/${getValues("college")}/${getValues("course_type")}`)
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const loadInitialData = () => {
    if (userData?.uid) {
      setLoader(true);
      axios
        .post("/getAllCoursesList")
        .then(function (response) {
          setFormData(response?.data?.data);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const toggleCourse = (id) => {
    if (userData?.uid) {
      setLoader(true);
      axios
        .get("/switchUserStatus/" + id)
        .then(function (response) {
          loadInitialData();
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const saveCourse = (data) => {
    if (userData?.uid) {
      const payload = {
        college_id: data?.college_id,
        name: data?.courseName,
        type: data?.type,
        price: data?.fees,
        code: data?.courseCode,
      };
      setLoader(true);
      axios
        .post("/saveCourseDetails", payload)
        .then(function (response) {
          if (!response?.data?.error) {
            setLoader(false);
            loadInitialData();
            setOpen(false);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            setLoader(false);
            setOpen(false);

            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          setLoader(false);
          setOpen(false);
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  const resetBtn = () => {
    setValue("college", "ALL");
    setValue("course_type", "ALL");
  };
  const switchStatusHandler = (id) => {
    toggleCourse(id);
  };
  const columns = [
    {
      name: "code",
      label: "Code",
      options: {
        setCellProps: (value) => {
          return {
            // className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          //   className: classes.columnHeader,
        }),
      },
    },
    {
      name: "college",
      label: "College",
    },
    {
      name: "name",
      label: "Course Name",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "price",
      label: "Fee",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = formData[dataIndex]?.status;
          return (
            <Switch
              checked={+status === 1}
              onChange={() => switchStatusHandler(formData[dataIndex]?.id)}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Edit",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(formData[dataIndex]);
                  setValue("college_id", formData[dataIndex]?.college_id);
                  setValue("type", formData[dataIndex]?.type);
                  setValue("courseName", formData[dataIndex]?.name);
                  setValue("courseCode", formData[dataIndex]?.code);
                  setValue("fees", formData[dataIndex]?.price);
                  setOpen(true);
                }}
              >
                <EditIcon color="secondary" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    customSearch: false,
    print: true,
  };
  const addNewCourse = () => {
    reset();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={addNewCourse}
                  icon={<AddIcon />}
                >
                  New Course
                </Button>
              </div>

              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  margin: "auto",
                  marginTop: "15px",
                  marginBottom: "15px",
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <div className="row">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">College</label>
                      <select
                        className="form-select"
                        name="college"
                        {...register("college")}
                        onChange={(e) => setValue(e.target.value)}
                      >
                        {[
                          { id: "ALL", name: "ALL" },
                          {
                            id: "1",
                            name: "Sourashtra College - Aided",
                          },
                          {
                            id: "2",
                            name: "Sourashtra College - Self - Finance",
                          },

                          {
                            id: "3",
                            name: "Sourashtra College For Women",
                          },
                        ].map((option) => (
                          <option key={option?.id} value={option?.id}>
                            {option?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Course Type</label>
                      <select
                        className="form-select"
                        name="course_type"
                        {...register("course_type")}
                        onChange={(e) => setValue(e.target.value)}
                      >
                        {[
                          { id: "ALL", name: "ALL" },
                          {
                            id: "UG",
                            name: "UG",
                          },
                          {
                            id: "PG",
                            name: "PG",
                          },
                        ].map((option) => (
                          <option key={option?.id} value={option?.id}>
                            {option?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="baseline"
                  spacing={2}
                >
                  <Button variant="contained" onClick={query}>
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    type="reset"
                    className="bg-danger"
                    onClick={resetBtn}
                  >
                    Reset
                  </Button>
                </Stack>
              </Paper>
              {formData?.length > 0 ? (
                <MUIDataTable
                  data={formData}
                  columns={columns}
                  options={options}
                />
              ) : (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="warning">
                    <h6>No Data Found..</h6>
                  </Alert>
                </Stack>
              )}
            </>
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {Object.keys(selectedRow).length === 0 ? "New Course" : "Edit Course"}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">College</label>
                <select
                  className="form-select"
                  name="college_id"
                  {...register("college_id")}
                  onChange={(e) => setValue(e.target.value)}
                >
                  {[
                    { id: "", name: "--SELECT--" },
                    {
                      id: "1",
                      name: "Sourashtra College - Aided",
                    },
                    {
                      id: "2",
                      name: "Sourashtra College - Self - Finance",
                    },

                    {
                      id: "3",
                      name: "Sourashtra College For Women",
                    },
                  ].map((option) => (
                    <option
                      key={option?.id}
                      value={option?.id}
                      selected={option?.id == ""}
                    >
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Course Type</label>
                <select
                  className="form-select"
                  name="type"
                  {...register("type")}
                  onChange={(e) => setValue(e.target.value)}
                >
                  {[
                    { id: "", name: "--SELECT--" },
                    {
                      id: "UG",
                      name: "UG",
                    },
                    {
                      id: "PG",
                      name: "PG",
                    },
                  ].map((option) => (
                    <option
                      key={option?.id}
                      value={option?.id}
                      selected={option?.id == ""}
                    >
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <FormInputText
                  name="courseName"
                  control={control}
                  label="Course Name"
                  errors={errors}
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <FormInputText
                  name="courseCode"
                  control={control}
                  label="Course Code"
                  errors={errors}
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <FormInputText
                  name="fees"
                  control={control}
                  label="Course Fee"
                  type="number"
                  errors={errors}
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit(saveCourse)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CourseFee;
