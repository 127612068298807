import "./App.css";
// import Login from "./screens/login/login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ApplicationIndex from "./screens/home/ApplicationIndex";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-photo-view/dist/react-photo-view.css";
import axios from "axios";
import LoginScreen from "./screens/login/login";
import SignUp from "./screens/signup/signup";
import { Toaster, toast } from "react-hot-toast";
import PreviewIndex from "./screens/admission/preview/PreviewIndex";
import Profile from "./screens/profile/Profile";
import DashboardIndex from "./screens/dashboard/DashboardIndex";
import { configureStore } from "@reduxjs/toolkit";
import CollegeDetails from "./screens/admission/college/CollegeDetails";
import ApplicationList from "./screens/admission/college/ApplicationList";
import Payment from "./screens/admission/payment/Payment";
import AlertDialog from "./components/alert/AlertDialog";
import PDFView from "./screens/admission/preview/PDFView";
import ForgetPassword from "./screens/password/ForgetPassword";
import { useEffect, useState } from "react";
import ApplicationListMaster from "./screens/applications/ApplicationListMaster";
import CourseFee from "./screens/courseFee/CourseFee";

export const store = configureStore({
  reducer: {},
});

function App() {
  // axios.defaults.baseURL = "http://localhost:8000/api/";
  // axios.defaults.baseURL = "https://college.dev24.in/capi/api/";
  // axios.defaults.baseURL =
  //   "https://admission.sourashtracollege.ac.in/server/api/";

  axios.defaults.baseURL =
    "https://apptest.sourashtracollege.ac.in/server/api/";

  const [userData, setUserData] = useState({});
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(data);
  }, []);

  return (
    // <div>
    //   <Sidenav />
    // </div>
    <Router>
      <Toaster />
      <AlertDialog />

      {/* <MiniDrawer /> */}
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        {/* <Route
          exact
          path="/login"
          element={
            !userData?.isLoggedIn ? (
              <LoginScreen />
            ) : (
              <Navigate replace to={"/dashboard"} />
            )
          }
        ></Route> */}
        <Route exact path="/login" element={<LoginScreen />}></Route>

        <Route exact path="/signup" element={<SignUp />}></Route>
        <Route
          exact
          path="new-application"
          element={<CollegeDetails />}
        ></Route>
        {/* <Route
          exact
          path="application-list"
          element={<ApplicationList />}
        ></Route> */}
        <Route
          exact
          path="application-list"
          element={<ApplicationList />}
        ></Route>
        <Route
          exact
          path="application-master"
          element={<ApplicationListMaster />}
        ></Route>
        <Route exact path="/application/preview" element={<PDFView />}></Route>
        <Route exact path="/profile" element={<ApplicationIndex />}></Route>
        <Route exact path="/dashboard" element={<DashboardIndex />}></Route>
        <Route exact path="/payment" element={<Payment />}></Route>
        <Route
          exact
          path="/forget-password"
          element={<ForgetPassword />}
        ></Route>
        <Route exact path="/course-fee" element={<CourseFee />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
