import React, { useEffect, useState } from "react";

import { Alert, Box, Button, Paper, Stack } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "../../layout/sideNav/sideNav";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom/dist";
import { setStepper } from "../../app/store/slice/counterSlice";
import { styled } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { DrawerHeader } from "../admission/preview/PreviewIndex";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";

const useStyles = styled({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
        margin: "auto",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
});
function ApplicationListMaster() {
  const navigate = useNavigate();
  const classes = useStyles();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    resetField,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    register,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data) => {
    axios
      .post("/getApplicationCourseList", data)
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState([]);

  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/getApplicationCourseList", { uid: userData?.uid })
        .then(function (response) {
          setFormData(response?.data?.data);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };

  useEffect(() => {
    // loadInitialData();
  }, []);
  const onExportSubmit = (data) => {
    if (userData?.uid) {
      axios
        .post("/export", data)
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "applications.csv"
            // `application_form_${data?.start_date}_to_${data?.end_date}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          toast.success("Exported successfully");
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const columns = [
    {
      name: "application_id",
      label: "Application No.",
      options: {
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "college",
      label: "College",
    },
    {
      name: "course",
      label: "Course",
    },
    {
      name: "fees",
      label: "Fee",
    },
    {
      name: "status",
      label: "Application Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = formData[dataIndex]?.status;
          let label;
          let classType;
          if (status == "I") {
            label = "Initiated";
            classType = "text-primary";
          } else if (status == "S") {
            label = "Submitted";
            classType = "text-success";
          } else if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "X") {
            label = "Error";
            classType = "text-danger";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "Payment Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = formData[dataIndex]?.payment_status;
          let label;
          let classType;
          if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "S") {
            label = "Paid";
            classType = "text-success";
          } else {
            label = "Awaiting confirmation";
            classType = "text-warning";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
    {
      name: "payment",
      label: "Payment",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="actionBtns"
            >
              {formData[dataIndex].payment_status == "S" ? (
                <Button
                  aria-label="edit"
                  onClick={() =>
                    navigate("/payment", {
                      state: {
                        applicationId: formData[dataIndex].application_id,
                        fees: formData[dataIndex].fee,
                        collegeId: formData[dataIndex].college_id,
                        courseId: formData[dataIndex].course_id,
                      },
                    })
                  }
                >
                  <PaymentIcon fontSize="large" />
                </Button>
              ) : (
                <h4>-</h4>
              )}
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
    {
      name: "Print",
      options: {
        setCellHeaderProps: () => ({
          className: `${classes.centeredHeader} ${classes.columnHeader}`,
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="actionBtns"
            >
              {formData[dataIndex]?.status == "S" ? (
                <Button
                  aria-label="edit"
                  onClick={() =>
                    navigate("/application/preview", {
                      state: {
                        uid: formData[dataIndex].uid,
                        collegeId: formData[dataIndex].college_id,
                        courseId: formData[dataIndex].course_id,
                        applicationId: formData[dataIndex].application_id,
                      },
                    })
                  }
                >
                  <LocalPrintshopIcon fontSize="large" color="secondary" />
                </Button>
              ) : (
                "-"
              )}
            </div>
          );
        },
        // setCellHeaderProps: () => ({ style: {alignItems}})
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    customSearch: false,
    print: true,
  };
  let today = new Date().toISOString()?.substring(0, 10);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  margin: "auto",
                  marginTop: "15px",
                  marginBottom: "15px",
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="baseline"
                    spacing={2}
                  > */}
                  <div className="row">
                    <div className="col-3">
                      <FormInputDate
                        name="start_date"
                        control={control}
                        label="Start Date"
                        type="date"
                        errors={errors}
                        defaultValue={today}
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <FormInputDate
                        name="end_date"
                        control={control}
                        label="End Date"
                        type="date"
                        errors={errors}
                        defaultValue={today}
                      />
                    </div>
                    <div className="col-3">
                      {" "}
                      <div className="mb-3">
                        <label className="form-label">College</label>
                        <select
                          className="form-select"
                          name="college"
                          {...register("college")}
                          onChange={(e) => setValue(e.target.value)}
                        >
                          {[
                            { id: "ALL", name: "ALL" },
                            {
                              id: "1",
                              name: "Sourashtra College - Aided",
                            },
                            {
                              id: "2",
                              name: "Sourashtra College - Self - Finance",
                            },

                            {
                              id: "3",
                              name: "Sourashtra College For Women",
                            },
                          ].map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Course Type</label>
                        <select
                          className="form-select"
                          name="course_type"
                          {...register("course_type")}
                          onChange={(e) => setValue(e.target.value)}
                        >
                          {[
                            { id: "ALL", name: "ALL" },
                            {
                              id: "UG",
                              name: "UG",
                            },
                            {
                              id: "PG",
                              name: "PG",
                            },
                          ].map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="baseline"
                    spacing={2}
                  >
                    <Button variant="contained" type="submit">
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      type="reset"
                      className="bg-danger"
                    >
                      Reset
                    </Button>
                    {formData?.length > 0 && (
                      <Button
                        variant="contained"
                        type="reset"
                        className="bg-warning"
                        onClick={handleSubmit(onExportSubmit)}
                      >
                        Export
                      </Button>
                    )}
                  </Stack>
                </form>
              </Paper>
              {formData?.length > 0 ? (
                <MUIDataTable
                  data={formData}
                  columns={columns}
                  options={options}
                />
              ) : (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="warning">
                    <h6>No Data Found..</h6>
                  </Alert>
                </Stack>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ApplicationListMaster;
