import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import SideNav from "../../layout/sideNav/sideNav";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import {
  countryListReducer,
  stateListReducer,
  cityListReducer,
  collegeListReducer,
  courseListReducer,
} from "../../app/store/slice/counterSlice";
import MUIDataTable from "mui-datatables";
import { styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom/dist";
import Loader from "../../components/loader/Loader";

const useStyles = styled({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  columnHeader: {
    "& span": {
      "& Button": {
        fontWeight: "600",
        fontFamily: "Poppins, sans-serif",
        margin: "auto",
      },
    },
  },
  arrangeCenter: {
    textAlign: "center",
  },
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rowFont: {
    " & div:nth-child(2)": {
      fontFamily: "Poppins, sans-serif",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
});
function DashboardIndex() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const collegeList = useSelector((state) => state.counter.collegeList);
  const courseList = useSelector((state) => state.counter.courseList);

  const [dataTables, setDataTables] = useState([]);
  const [application, setApplication] = useState([]);
  const [dashboard, setDashboard] = useState({});
  const [loader, setLoader] = useState(true);

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const loadInitialData = () => {
    let country = axios.get("/getCountryList");
    let state = axios.get("/getStateList");
    let city = axios.get("/getCityList");
    let applicationStatus = axios.post("/getApplicationCourses", {
      uid: userData?.uid,
    });
    let college = axios.get("/getCollegeList");
    let courses = axios.get("/getCoursesList");
    let dashboard = axios.get("/dashboard");
    if (userData?.uid) {
      axios
        .all([
          applicationStatus,
          country,
          state,
          city,
          // formDatas,
          // applicationStatus,
          college,
          courses,
          dashboard,
        ])
        .then(function (response) {
          setApplication(response[0]?.data?.data);
          dispatch(countryListReducer(response[1]?.data?.data));
          dispatch(stateListReducer(response[2]?.data?.data));
          dispatch(cityListReducer(response[3]?.data?.data));
          dispatch(collegeListReducer(response[4]?.data?.data));
          dispatch(courseListReducer(response[5]?.data?.data));
          setDashboard(response[6]?.data?.data);
          setLoader(false);
        })
        .catch(function (err) {
          setLoader(false);
          toast.error(err?.message);
        });
    } else {
      // toast.error("User ID not found");
    }
  };

  useEffect(() => {
    loadInitialData();
  }, [userData?.uid]);
  const columns = [
    {
      name: "application",
      label: "Application No.",
      options: {
        setCellProps: (value) => {
          return {
            className: `${classes.rowFont} ${classes.arrangeCenter}`,
          };
        },
        setCellHeaderProps: () => ({
          className: classes.columnHeader,
        }),
      },
    },
    {
      name: "college",
      label: "College",
    },
    {
      name: "courseName",
      label: "Course",
    },
    {
      name: "status",
      label: "Application Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = dataTables[rowIndex].status;
          let label;
          let classType;
          if (status == "I") {
            label = "Initiated";
            classType = "text-primary";
          } else if (status == "S") {
            label = "Submitted";
            classType = "text-success";
          } else if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "X") {
            label = "Error";
            classType = "text-danger";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "Payment Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = dataTables[rowIndex].payment_status;
          let label;
          let classType;
          if (status == "P") {
            label = "Pending";
            classType = "text-danger";
          } else if (status == "S") {
            label = "Paid";
            classType = "text-success";
          } else {
            label = "Awaiting confirmation";
            classType = "text-warning";
          }
          return (
            <h6 className={classType}>
              <b>{label}</b>
            </h6>
          );
        },
      },
    },
  ];
  useEffect(() => {
    const tableRows = application?.map((row) => {
      const college = collegeList.find((item) => row?.college_id == item?.id);
      const course = courseList.find((item) => row?.course_id == item?.id);
      return {
        application: row?.application_id,
        college: college?.name,
        course: course?.name,
        courseName: course?.name,
        fee: row?.fees,
        status: row?.status,
        payment_status: row?.payment_status,
      };
    });
    setDataTables(tableRows);
  }, [application]);

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    customSearch: false,
    print: false,
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  return loader ? (
    <Loader />
  ) : (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {userData?.role == "3" && (
            <>
              <h4 className="text-primary mt-4">Dashboard</h4>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  margin: "auto",
                  marginTop: "15px",
                  flexGrow: 1,
                  width: "100%",
                }}
                className="bg-light"
              >
                <ul>
                  <li>Click "My Profile" at the left sidebar</li>
                  <li>Choose the degree of application (UG or PG)</li>
                  <li>Click Save and proceed Next</li>
                  <li>
                    Fill all the Personal details properly, Click Save and
                    proceed Next
                  </li>
                  <li>
                    Fill all the School, Mark, and other details, Click Save and
                    proceed Next
                  </li>
                  <li>
                    Upload Documents whichever is available (Optional only)
                  </li>
                  <li>
                    If you have filled all profile details properly, you will
                    notice "All profile steps completed. Go to Application menu
                    to proceed."
                  </li>
                  <li>
                    Click "Application" (Note: Without filling the profile
                    details, application cannot be submitted)
                  </li>
                  <li>
                    Click +Apply Application, Choose the college and then
                    course, application fees will be displayed, click preview to
                    proceed further.
                  </li>
                  <li>
                    Preview all the fields, tick the Consent and Submit the
                    application
                  </li>
                  <li>
                    Upon successful submission of application, proceed to
                    payment
                  </li>
                  <li>
                    Go to Payment screen, pay the respectively amount displayed
                    and fill the payment details
                  </li>
                  <li>
                    Management will cross-check and update the application and
                    payment status
                  </li>
                  <li>Status can be verified on the Dashboard.</li>
                </ul>
              </Paper>
              <h4 className="text-primary mt-4">Status</h4>
            </>
          )}
          {userData?.role == "3" && dataTables.length > 0 ? (
            <MUIDataTable
              data={dataTables}
              columns={columns}
              options={options}
            />
          ) : (
            userData?.role == "3" && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="info">
                  <h6>
                    No Application Submitted Yet, Please click on&nbsp;
                    <Link to="/application-list">
                      <b>Apply Application</b>
                    </Link>{" "}
                    to proceed.
                  </h6>
                </Alert>
              </Stack>
            )
          )}
          {userData?.role != "3" && (
            <div className="row justify-content-evenly">
              <div className="col-4">
                <Card sx={{ minWidth: 275 }} className="bg-warning">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="white"
                      gutterBottom
                    >
                      Total Applications
                    </Typography>
                    <hr />
                    <Typography variant="h4" color="white" component="div">
                      {dashboard?.totalCount | 0}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-4">
                <Card sx={{ minWidth: 275 }} className="bg-danger">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="white"
                      gutterBottom
                    >
                      Todays Application Count
                    </Typography>
                    <hr />

                    <Typography variant="h4" color="white" component="div">
                      {dashboard?.todayCount | 0}
                    </Typography>
                  </CardContent>
                </Card>
              </div>

              <div className="col-4">
                <Card sx={{ minWidth: 275 }} className="bg-light">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      // color="white"
                      gutterBottom
                    >
                      Un Paid Applications
                    </Typography>
                    <hr />

                    <Typography variant="h4" component="div">
                      {dashboard?.unPaidCount | 0}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
          <h4 className="text-primary mt-4">Contact Us</h4>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
              width: "100%",
            }}
            className="bg-light"
          >
            <h6>
              <b>Sourashtra College</b>
            </h6>
            <p className="mb-0">
              Contact No. : 8754206663, 8754208885, 8754209994{" "}
            </p>
            <p> email : soucollege@gmail.com</p>

            <h6>
              <b>Sourashtra College for Women </b>
            </h6>
            <p className="mb-0">Contact No. :9952370329 </p>
            <p> email : soucolwomen@yahoo.co.in</p>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default DashboardIndex;
