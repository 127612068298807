import React from "react";
import PropTypes from "prop-types";
import { DISTRICT_LIST, LOGO } from "../../../app/utlils";
import { useSelector } from "react-redux";

function PDFDownloadPG({
  formData,
  data,
  applicationId,
  reference,
  handleCheckChange,
}) {
  const couseType = useSelector((state) => state.counter.couseType);
  const { stateList, cityList, courseList, collegeId, courseId } = data;
  const parseDate = (date) => {
    var d = new Date(date);

    var datestring =
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear();
    return datestring;
  };
  const parseMonthYear = (value) => {
    const date = new Date(value);
    const formattedDate =
      date.toLocaleString("default", { month: "short" }) +
      "-" +
      date.getFullYear();
    return formattedDate;
  };
  const imageUrl = formData?.attachments?.find(
    (img) => img?.type == "student_photo"
  )?.file;

  const signUrl = formData?.attachments?.find(
    (img) => img?.type == "signature"
  )?.file;

  let today = new Date().toLocaleDateString();
  const applicationData = () => {
    return formData?.applications?.find(
      (row) => row?.application_id == applicationId
    );
  };
  const parseApplicationNo = (appNo) => {
    const code = appNo?.split("-")[0];
    const number = appNo?.split("-")[1];
    const submitted_at = applicationData()?.submitted_at?.substring(0, 4);
    return `${code}-${number}/${submitted_at}`;
  };
  return (
    <div ref={reference} style={{ margin: "40px" }}>
      <div className="container application-view" ref={reference}>
        <div>
          {applicationId && (
            <h6 className="fs-6 pl-5">
              <b>Application No. :</b>{" "}
              <span style={{ border: "1px solid black", padding: "3px 10px" }}>
                {parseApplicationNo(applicationId)}
              </span>
            </h6>
          )}
          <div className="row">
            <div className="col-2 d-flex justify-content-center align-items-center">
              <img src={LOGO} alt="" width={100} />
            </div>
            <div className="col-8">
              <h5 className="text-center">
                <b>{collegeId == 1 && "SOURASHTRA COLLEGE"}</b>
                <b>{collegeId == 2 && "SOURASHTRA COLLEGE (SELF FINANCE)"}</b>
                <b>{collegeId == 3 && "SOURASHTRA COLLEGE FOR WOMEN"}</b>
              </h5>

              <div className="heading-content">
                {collegeId != 3 && (
                  <p className="text-center" style={{ fontWeight: "600" }}>
                    (Autonomous)
                  </p>
                )}

                <p>(A Linguistic Minority Co-Educational Institution)</p>
                <p style={{ whiteSpace: "nowrap" }}>
                  (Afflilated to Madurai Kamaraj University and Reaccredited
                  with B+ Grade by NAAC)
                </p>
                <p>VILACHERY MAIN ROAD, PASUMALAI (PO), MADURAI-625004.</p>
              </div>
            </div>
            {imageUrl && (
              <div className="col-2 d-flex justify-content-center align-items-center py-1">
                <img src={imageUrl} alt="" width={100} />
              </div>
            )}

            <hr />
            <div className="row">
              <div className="col-12">
                <h6
                  style={{
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  APPLICATION FORM
                </h6>
              </div>
            </div>
            <div className="application-details">
              <table width={"100%"} border={1}>
                <tr>
                  {/* <th>APPLICATION NO : 122122</th> */}
                  <th className=" fs-6 ">
                    Course : &emsp;
                    {applicationData()?.course
                      ? applicationData()?.course
                      : courseList?.find((item) => courseId == item?.id)?.name}
                  </th>
                </tr>
              </table>
              <table width={"100%"} border={1} className="mt-1 form-table">
                <tr>
                  <td>
                    <span className="label"> 1. Name :</span>
                    <span className="value">{formData?.name}</span>
                  </td>
                  <td>
                    <span className="label"> 1(a). Initial :</span>
                    <span className="value">{formData?.initial}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">
                      2. Place of birth & district :{" "}
                    </span>
                    <span className="value">{formData?.place_of_birth}</span>
                  </td>
                  <td>
                    <span className="label">2(a). Sex : </span>
                    <span className="value">
                      {formData?.gender == "M" && "Male"}
                      {formData?.gender == "F" && "Female"}
                      {formData?.gender == "T" && "Trans-Gender"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">2(b). Date of birth :</span>
                    &emsp;
                    <span className="value">
                      {parseDate(formData?.dob?.substring(0, 10))}
                    </span>
                  </td>
                  <td>
                    <span className="label">2(c). Religion : </span>
                    <span className="value">{formData?.religion}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">3. Community : </span>
                    <span className="value">{formData?.community}</span>
                  </td>
                  <td>
                    <span className="label">4. Nationality : </span>
                    <span className="value">{formData?.nationality}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="label">5. Mother tongue : </span>
                    <span className="value"> {formData?.mother_tongue}</span>
                  </td>
                  <td>
                    <span className="label">6. Caste : </span>
                    <span className="value"> {formData?.caste}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">7. Aadhar no : </span>
                    <span className="value">{formData?.aadhar_no}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">7(a). NAD ID : </span>
                    <span className="value"> {formData?.nad_id}</span>
                  </td>
                  <td>
                    <span className="label">7(b). PAN no. : </span>
                    <span className="value">{formData?.pan}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">8. Blood group : </span>
                    <span className="value">{formData?.blood_group}</span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td colSpan={2}>
                    <span className="label">9. Name of parent/guardian : </span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td style={{ padding: "0px 50px" }}>
                    &emsp;&emsp;
                    <span className="label" style={{ fontSize: "12px" }}>
                      Father name :
                    </span>
                    <span className="value" style={{ fontSize: "12px" }}>
                      {formData?.father_name}
                    </span>
                  </td>
                  <td style={{ padding: "0px 50px" }}>
                    <span className="label" style={{ fontSize: "12px" }}>
                      Mother :
                    </span>
                    <span className="value" style={{ fontSize: "12px" }}>
                      {formData?.mother_name}
                    </span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td style={{ padding: "0px 50px" }}>
                    &emsp;&emsp;
                    <span className="label" style={{ fontSize: "12px" }}>
                      Mobile no. :
                    </span>
                    <span className="value" style={{ fontSize: "12px" }}>
                      {formData?.father_mobile}
                    </span>
                  </td>
                  <td style={{ padding: "0px 50px" }}>
                    <span className="label" style={{ fontSize: "12px" }}>
                      Mobile no. :
                    </span>
                    <span className="value" style={{ fontSize: "12px" }}>
                      {formData?.mother_mobile}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">10. Occupation : </span>
                    <span className="value">{formData?.father_occupation}</span>
                  </td>
                  <td>
                    <span className="label">11. Annual income : </span>
                    <span className="value">
                      ₹ {formData?.father_annual_income}
                    </span>
                  </td>
                </tr>
                <tr className="border-0  ">
                  <td className="py-0">
                    <span className="label">
                      12. Communication address :
                      <p className="value m-0">
                        {formData?.communication_address_line_1}
                      </p>
                      <p className="value  m-0">
                        {formData?.communication_address_line_2},
                        {formData?.communication_address_line_3}
                      </p>
                      <p className="value  m-0">
                        Pincode:
                        {formData?.communication_pincode}
                      </p>
                      <p className="value  m-0">
                        City :
                        {
                          cityList?.find(
                            (item) => formData?.communication_city == item?.id
                          )?.name
                        }
                      </p>
                      {formData?.permanent_district == "other" ? (
                        <p className="value  m-0">
                          {formData?.communication_other_district}
                        </p>
                      ) : (
                        <p className="value  m-0">
                          District :
                          {
                            DISTRICT_LIST?.find(
                              (item) =>
                                formData?.communication_district == item?.id
                            )?.name
                          }
                        </p>
                      )}
                      <p className="value  m-0">
                        {
                          stateList?.find(
                            (item) => formData?.communication_state == item?.id
                          )?.name
                        }
                      </p>
                    </span>
                  </td>
                  <td className="py-0">
                    <span className="label">
                      13. Permanent address :
                      <p className="value m-0">
                        {formData?.permanent_address_line_1}
                      </p>
                      <p className="value  m-0">
                        {formData?.permanent_address_line_2},
                        {formData?.permanent_address_line_3},
                      </p>
                      <p className="value  m-0">
                        Pincode:
                        {formData?.permanent_pincode}
                      </p>
                      <p className="value  m-0">
                        City :
                        {
                          cityList?.find(
                            (item) => formData?.permanent_city == item?.id
                          )?.name
                        }
                      </p>
                      {formData?.permanent_district == "other" ? (
                        <p className="value  m-0">
                          {formData?.permanent_other_district}
                        </p>
                      ) : (
                        <p className="value  m-0">
                          District :
                          {
                            DISTRICT_LIST?.find(
                              (item) => formData?.permanent_district == item?.id
                            )?.name
                          }
                        </p>
                      )}
                      <p className="value  m-0">
                        {
                          stateList?.find(
                            (item) => formData?.permanent_state == item?.id
                          )?.name
                        }
                      </p>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="label">14. Mobile no. : </span>
                    <span className="value">{formData?.mobile}</span> <br />
                    <span className="label">14(A). WhatsApp Mobile no. : </span>
                    <span className="value">{formData?.mobile_whatsapp}</span>
                  </td>
                  <td>
                    <span className="label">14(B). Email : </span>
                    <span
                      className="value"
                      style={{ textTransform: "lowercase" }}
                    >
                      {formData?.email}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">
                      15. College previously studied with date of admission &
                      withdrawal :
                    </span>
                    <span className="value">{formData?.date_of_admission}</span>
                  </td>
                </tr>
                <tr className="page-break">
                  <td colSpan={2}>
                    <span className="label">
                      16. Reasons for break of study, if any :
                    </span>
                    <span className="value">{formData?.reason_for_break}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">
                      17. Any information deserving special consideration of the
                      applicant :
                    </span>
                    <span className="value">
                      {formData?.special_consideration}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label">
                      18. Distinction in sport/ncc/nss :
                    </span>
                    <span className="value">
                      {formData?.distinction_sports == "Y" ? "Yes" : "No"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">19. Name of the college :</span>
                    <span className="value">{formData?.college_name}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">20. Name of the University :</span>
                    <span className="value">{formData?.university}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">
                      21. Name of qualifying degree :
                    </span>
                    <span className="value">{formData?.degree}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">22. Register Number :</span>
                    <span className="value">{formData?.reg_no}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <span className="label">23. Month & year of passing :</span>
                    <span className="value">
                      {parseMonthYear(formData?.month_year)}
                    </span>
                  </td>
                </tr>
              </table>
              <br />
              <br />
              <h5>Mark Details</h5>

              <table width={"100%"} id="mark-table-preview" className="mt-1">
                <tr>
                  <th>Part</th>
                  <th className="custom-subject-width">Subject</th>
                  <th className="custom-mark-width">Mark</th>
                  <th className="custom-mark-width">Maximum</th>
                  <th>Class</th>
                  <th>Percentage</th>
                </tr>
                <tr>
                  <td>{formData?.mark_details?.[0]?.part}</td>
                  <td className="custom-subject-width capitalize">
                    {formData?.mark_details?.[0]?.subject}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[0]?.marks}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[0]?.maximum}
                  </td>
                  <td>{formData?.mark_details?.[0]?.class?.toUpperCase()}</td>
                  <td>{formData?.mark_details?.[0]?.percentage}</td>
                </tr>
                <tr>
                  <td>{formData?.mark_details?.[1]?.part}</td>
                  <td className="custom-subject-width capitalize">
                    {formData?.mark_details?.[1]?.subject}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[1]?.marks}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[1]?.maximum}
                  </td>
                  <td>{formData?.mark_details?.[1]?.class?.toUpperCase()}</td>
                  <td>{formData?.mark_details?.[1]?.percentage}</td>
                </tr>
                <tr>
                  <td>{formData?.mark_details?.[2]?.part}</td>
                  <td className="custom-subject-width capitalize">
                    {formData?.mark_details?.[2]?.subject}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[2]?.marks}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[2]?.maximum}
                  </td>
                  <td>{formData?.mark_details?.[2]?.class?.toUpperCase()}</td>
                  <td>{formData?.mark_details?.[2]?.percentage}</td>
                </tr>
                <tr>
                  <td>{formData?.mark_details?.[3]?.part}</td>
                  <td className="custom-subject-width capitalize">
                    {formData?.mark_details?.[3]?.subject}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[3]?.marks}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[3]?.maximum}
                  </td>
                  <td>{formData?.mark_details?.[3]?.class?.toUpperCase()}</td>
                  <td>{formData?.mark_details?.[3]?.percentage}</td>
                </tr>
                <tr>
                  <td>{formData?.mark_details?.[4]?.part}</td>
                  <td className="custom-subject-width capitalize">
                    {formData?.mark_details?.[4]?.subject}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[4]?.marks}
                  </td>
                  <td className="custom-mark-width">
                    {formData?.mark_details?.[4]?.maximum}
                  </td>
                  <td>{formData?.mark_details?.[4]?.class?.toUpperCase()}</td>
                  <td>{formData?.mark_details?.[4]?.percentage}</td>
                </tr>
                {formData?.mark_details?.[5] && (
                  <tr>
                    <td>{formData?.mark_details?.[5]?.part}</td>
                    <td className="custom-subject-width capitalize">
                      {formData?.mark_details?.[5]?.subject}
                    </td>
                    <td className="custom-mark-width">
                      {formData?.mark_details?.[5]?.marks}
                    </td>
                    <td className="custom-mark-width">
                      {formData?.mark_details?.[5]?.maximum}
                    </td>
                    <td>{formData?.mark_details?.[5]?.class?.toUpperCase()}</td>
                    <td>{formData?.mark_details?.[5]?.percentage}</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={2}>
                    <b>TOTAL</b>
                  </td>
                  <td className="custom-mark-width">
                    <b>
                      {+formData?.mark_details?.[0]?.marks +
                        +formData?.mark_details?.[1]?.marks +
                        +formData?.mark_details?.[2]?.marks +
                        +formData?.mark_details?.[3]?.marks +
                        +formData?.mark_details?.[4]?.marks +
                        (+formData?.mark_details?.[5]?.marks | 0)}
                    </b>
                  </td>
                  <td className="custom-mark-width">
                    <b>
                      {+formData?.mark_details?.[0]?.maximum +
                        +formData?.mark_details?.[1]?.maximum +
                        +formData?.mark_details?.[2]?.maximum +
                        +formData?.mark_details?.[3]?.maximum +
                        +formData?.mark_details?.[4]?.maximum +
                        (+formData?.mark_details?.[5]?.maximum | 0)}
                    </b>
                  </td>
                  <td> </td>
                  <td></td>
                </tr>
              </table>
              <div className="row  mt-2">
                <div className="col">
                  <h6>
                    Consent <span className="text-danger">*</span>
                  </h6>
                  <div className="form-check">
                    <input
                      name="consent "
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleCheckChange}
                      disabled={applicationId ? true : false}
                      defaultChecked={applicationId ? true : false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                      style={{ color: "red" }}
                    >
                      <ol>
                        <li>
                          I declare that all the particulars furnished above are
                          true and correct
                        </li>
                        <li>
                          I submit that I will abide by the rules and
                          regulations of the college
                        </li>
                      </ol>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-6 d-flex align-items-start">
                  <h6>Place : </h6>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  {signUrl && <img src={signUrl} width={100} height={50} />}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <h6 className="text-start">
                    Date :{" "}
                    {applicationData()?.submitted_at
                      ? parseDate(applicationData()?.submitted_at)?.substring(
                          0,
                          10
                        )
                      : parseDate(today)}
                  </h6>
                </div>

                <div className="col-6">
                  <h6 className="text-end">Signature of applicant</h6>
                </div>
              </div>
              {applicationData()?.payment_status == "S" && (
                <>
                  <hr />
                  <h5 className="text-center">Payment Details</h5>
                  <div className="row mt-3">
                    <div className="col-6">
                      <h6>Transaction No. : {applicationData()?.txn_no}</h6>
                      <h6>
                        Payment date :{" "}
                        {parseDate(applicationData()?.payment_date)}
                      </h6>
                      <h6>Remarks : {applicationData()?.remarks}</h6>
                    </div>
                    <div className="col-6">
                      <h6>Payment type : {applicationData()?.payment_type}</h6>
                      <h6>Amount : ₹ {applicationData()?.amount_paid}</h6>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PDFDownloadPG.propTypes = {};

export default PDFDownloadPG;
