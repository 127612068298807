import React from "react";
import { Controller, useFormContext } from "react-hook-form";
const DATE_FORMAT = "dd-MMM-yy";

export const TableInputDate = ({
  name,
  control,
  label,
  errors,
  type,
  defaultValue,
  mandatory,
}) => {
  const dateRef = new Date();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <input
          type={type}
          className="form-control"
          placeholder={`Enter ${label}`}
          onChange={onChange}
          value={defaultValue}
          max={`${dateRef.getFullYear()}-${(
            "0" +
            (dateRef.getMonth() + 1)
          ).slice(-2)}`}
        />
      )}
    />
  );
};
