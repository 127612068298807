import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import Stack from "@mui/material/Stack";
import { FormInputRadio } from "../../../components/form-components/FormInputRadio";
import { personalDetailSchema } from "../../../app/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { CASTE_LIST, DISTRICT_LIST } from "../../../app/utlils";
import { showModal } from "../../../app/store/slice/counterSlice";
import AlertDialog from "../../../components/alert/AlertDialog";

function PersonalDetails({ activeStep }) {
  const dispatch = useDispatch();
  const { countryList, stateList, cityList, couseType } = useSelector(
    (state) => state.counter
  );

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);

  const [formStatus, setFormStatus] = useState({});
  const [formData, setFormData] = useState({});
  const [otherCDistrict, setOtherCDistrict] = useState("");
  const [otherPDistrict, setOtherPDistrict] = useState("");

  const handleNumericChange = (event, name) => {
    if (event.target.value) {
      clearErrors(name);
    }
    const result = event.target.value.replace(/\D/g, "");
    setValue(name, result);
  };
  const methods = useForm({
    resolver: yupResolver(personalDetailSchema),
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const {
    handleSubmit,
    reset,
    resetField,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    register,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data) =>
    saveForm({
      ...data,
      active_step: activeStep,
      uid: userData?.uid,
    });
  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    let formStatus = axios.post("/getFormStatus", { uid: userData?.uid });
    let formData = axios.post("/fetchFormData", { uid: userData?.uid });
    if (userData?.uid) {
      await axios
        .all([formStatus, formData])
        .then(function (response) {
          setFormStatus(response[0]?.data?.data);
          setFormData(response[1]?.data?.data);
          const otherCDistricts =
            response[1]?.data?.data?.communication_district;
          otherCDistricts == "other"
            ? setOtherCDistrict(true)
            : setOtherCDistrict(false);
          const otherPDistricts =
            response[1]?.data?.data?.communication_district;
          otherPDistricts == "other"
            ? setOtherPDistrict(true)
            : setOtherPDistrict(false);
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const saveForm = (data) => {
    if (data?.uid) {
      axios
        .post("/savePersonalDetails", data)
        .then(function (response) {
          if (!response?.data?.error) {
            // toast.success(response?.data?.message);
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            if (response?.data?.data) {
              Object.values(response?.data?.data).map((error, index) => {
                errDesc =
                  index === 0
                    ? errDesc + error + "\n "
                    : errDesc + error + "\n ";
              });
            } else {
              errDesc = response?.data?.message;
            }

            dispatch(
              showModal({
                msg: errDesc,
                error: response?.data?.error,
              })
            );
            // toast.error(errDesc);
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
          // toast.error(error?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  const communicationDistrictHandler = (value, name) => {
    value && clearErrors(name);
    if (value == "other") {
      setOtherCDistrict(true);
    } else {
      setOtherCDistrict(false);
    }
    setValue(name, value);
  };
  const permanentDistrictHandler = (value, name) => {
    value && clearErrors(name);
    if (value == "other") {
      setOtherPDistrict(true);
    } else {
      setOtherPDistrict(false);
    }
    setValue(name, value);
  };
  // const addressSwtich = (e) => {
  //   if (e.target.checked) {
  //     const pAddress1 = getValues("permanent_address_line_1");
  //     const pAddress2 = getValues("communication_address_line_2");
  //     const pAddress3 = getValues("communication_address_line_3");
  //     const city = getValues("communication_city");
  //     const state = getValues("communication_state");
  //     const district = getValues("communication_district");
  //     const otherDistrict = getValues("communication_other_district");
  //     const pincode = getValues("communication_pincode");

  //     pAddress1 && clearErrors("permanent_address_line_1");
  //     pAddress2 && clearErrors("permanent_address_line_2");
  //     pAddress3 && clearErrors("permanent_address_line_3");
  //     city && clearErrors("permanent_city");
  //     district && clearErrors("permanent_district");
  //     otherDistrict && clearErrors("permanent_other_district");
  //     state && clearErrors("permanent_state");
  //     pincode && clearErrors("permanent_pincode");

  //     setFormData({
  //       ...formData,
  //       permanent_city: city,
  //       permanent_state: state,
  //       permanent_address_line_1: pAddress1,
  //       permanent_address_line_2: pAddress2,
  //       permanent_address_line_3: pAddress3,
  //     });
  //     // console.log(pAddress1);

  //     setValue("permanent_address_line_1", pAddress1);
  //     setValue("permanent_address_line_2", pAddress2);
  //     setValue("permanent_address_line_3", pAddress3);
  //     setValue("permanent_city", city);
  //     setValue("permanent_district", district);
  //     setValue("permanent_other_district", otherDistrict);
  //     setValue("permanent_state", state);
  //     setValue("permanent_pincode", pincode);
  //   } else {
  //     setFormData({
  //       ...formData,
  //       permanent_city: "",
  //       permanent_state: "",
  //       permanent_address_line_1: "",
  //       permanent_address_line_2: "",
  //       permanent_address_line_3: "",
  //     });
  //     resetField("permanent_address_line_1");
  //     resetField("permanent_address_line_2");
  //     resetField("permanent_address_line_3");
  //     resetField("permanent_city");
  //     resetField("permanent_district");
  //     resetField("permanent_other_district");
  //     resetField("permanent_state");
  //     resetField("permanent_pincode");
  //   }
  // };
  const addressSwtich = (e) => {
    if (e.target.checked) {
      const pAddress1 = getValues("communication_address_line_1");
      const pAddress2 = getValues("communication_address_line_2");
      const pAddress3 = getValues("communication_address_line_3");
      const city = getValues("communication_city");
      const state = getValues("communication_state");
      const district = getValues("communication_district");
      const otherCDistrict = getValues("communication_other_district");
      const pincode = getValues("communication_pincode");

      pAddress1 && clearErrors("permanent_address_line_1");
      pAddress2 && clearErrors("permanent_address_line_2");
      pAddress3 && clearErrors("permanent_address_line_3");
      city && clearErrors("permanent_city");
      state && clearErrors("permanent_state");
      district && clearErrors("permanent_district");
      otherPDistrict && clearErrors("permanent_other_district");
      pincode && clearErrors("permanent_pincode");
      otherCDistrict && setOtherPDistrict(otherCDistrict);

      setFormData({
        ...formData,
        permanent_city: city,
        permanent_state: state,
        permanent_address_line_1: pAddress1,
        permanent_address_line_2: pAddress2,
        permanent_address_line_3: pAddress3,
      });

      setValue("permanent_address_line_1", pAddress1);
      setValue("permanent_address_line_2", pAddress2);
      setValue("permanent_address_line_3", pAddress3);
      setValue("permanent_city", city);
      setValue("permanent_district", district);
      setValue("permanent_other_district", otherCDistrict);
      setValue("permanent_state", state);
      setValue("permanent_pincode", pincode);
    } else {
      setFormData({
        ...formData,
        permanent_city: "",
        permanent_state: "",
        permanent_address_line_1: "",
        permanent_address_line_2: "",
        permanent_address_line_3: "",
      });
      resetField("permanent_address_line_1");
      resetField("permanent_address_line_2");
      resetField("permanent_address_line_3");
      resetField("permanent_city");
      resetField("permanent_district");
      resetField("permanent_other_district");
      resetField("permanent_state");
      resetField("permanent_pincode");
    }
  };

  useEffect(() => {
    // Object.keys(errors).length > 0 &&
    //   dispatch(
    //     showModal({
    //       msg: "Please fill all the mandatory fields",
    //       error: true,
    //     })
    //   );
  }, [errors]);

  return loader ? (
    <Loader />
  ) : (
    <>
      <h5 className="text-primary mt-4">Personal Details</h5>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="name"
              control={control}
              label="Name (Without Initial)"
              type="text"
              errors={errors}
              defaultValue={formData?.name}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="initial"
              control={control}
              label="Initial"
              type="text"
              errors={errors}
              defaultValue={formData?.initial}
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div className="mb-3">
              <label className="form-label">
                Aadhar Number
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("aadhar_no")}
                className="form-control"
                placeholder="Eg: 123412341234"
                maxLength="12"
                defaultValue={formData?.aadhar_no}
                onChange={(e) => handleNumericChange(e, "aadhar_no")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["aadhar_no"]?.message}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="place_of_birth"
              control={control}
              label="Place Of Birth"
              type="text"
              placeholder=""
              errors={errors}
              defaultValue={formData?.place_of_birth}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDate
              name="dob"
              control={control}
              label="Date of Birth"
              type="date"
              errors={errors}
              defaultValue={formData?.dob?.substring(0, 10)}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputRadio
              name="gender"
              control={control}
              label="Gender"
              errors={errors}
              cOptions={[
                {
                  label: "Male",
                  value: "M",
                },
                {
                  label: "Female",
                  value: "F",
                },
                {
                  label: "Trans-Gender",
                  value: "T",
                },
              ]}
              defaultValue={formData?.gender}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="mother_tongue"
              control={control}
              label="Mother Tongue"
              type="text"
              placeholder="Eg:Tamil"
              errors={errors}
              defaultValue={formData?.mother_tongue}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          {formData?.applying_for == "PG" && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormInputText
                name="nad_id"
                control={control}
                label="NAD ID"
                type="text"
                placeholder=""
                errors={errors}
                defaultValue={formData?.nad_id}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="pan"
              control={control}
              label="PAN No."
              type="text"
              placeholder="Eg: AAAA1234A"
              errors={errors}
              className="uppercase"
              defaultValue={formData?.pan}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              type="text"
              placeholder="john@doe.com"
              errors={errors}
              defaultValue={formData?.email || userData?.email}
              mandatory="true"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div className="mb-3">
              <label className="form-label">
                Student Mobile No.
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("mobile")}
                className="form-control"
                placeholder="Eg: 987654321"
                maxLength="10"
                defaultValue={formData?.mobile}
                onChange={(e) => handleNumericChange(e, "mobile")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["mobile"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="mobile"
              control={control}
              label="Student Mobile No."
              type="text"
              errors={errors}
              defaultValue={formData?.mobile}
              mandatory="true"
              maxLength={10}
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div className="mb-3">
              <label className="form-label">
                Student WhatsApp Mobile No.
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("mobile_whatsapp")}
                className="form-control"
                placeholder="Eg: 987654321"
                maxLength="10"
                defaultValue={formData?.mobile_whatsapp}
                onChange={(e) => handleNumericChange(e, "mobile_whatsapp")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["mobile_whatsapp"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="mobile_whatsapp"
              control={control}
              label="Student WhatsApp Mobile No."
              type="text"
              errors={errors}
              defaultValue={formData?.mobile_whatsapp}
              maxLength={10}
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={12} xl={12}>
            <hr />
            <h6 className="text-primary">Communication Address</h6>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="communication_address_line_1"
              control={control}
              label="Address Line 1"
              type="text"
              errors={errors}
              defaultValue={formData?.communication_address_line_1}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="communication_address_line_2"
              control={control}
              label="Address Line 2"
              type="text"
              errors={errors}
              defaultValue={formData?.communication_address_line_2}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="communication_address_line_3"
              control={control}
              label="Address Line 3"
              type="text"
              errors={errors}
              defaultValue={formData?.communication_address_line_3}
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="communication_city"
              control={control}
              label="City"
              type="single"
              options={cityList}
              errors={errors}
              defaultValue={formData?.communication_city}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div>
              <label className="form-label">
                District
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <select
                className="form-select"
                name="communication_district"
                {...register("communication_district")}
                onChange={(e) =>
                  communicationDistrictHandler(
                    e.target.value,
                    "communication_district"
                  )
                }
              >
                <option value="">--SELECT--</option>
                {DISTRICT_LIST.map((option) => (
                  <option
                    key={option?.id}
                    value={option?.id}
                    selected={formData?.communication_district == option?.id}
                  >
                    {option?.name}
                  </option>
                ))}
              </select>
              <p className="input-error-desc small">
                {errors?.["communication_district"]?.message}
              </p>
            </div>
          </Grid>
          {otherCDistrict && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormInputText
                name="communication_other_district"
                control={control}
                label="District"
                type="text"
                errors={errors}
                defaultValue={formData?.communication_other_district}
                mandatory="true"
                className={"capitalize"}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="communication_state"
              control={control}
              label="State/Province"
              type="single"
              options={stateList}
              errors={errors}
              defaultValue={formData?.communication_state}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div className="mb-3">
              <label className="form-label">
                Pincode
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("communication_pincode")}
                className="form-control"
                placeholder="Eg: 654321"
                maxLength="6"
                defaultValue={formData?.communication_pincode}
                onChange={(e) =>
                  handleNumericChange(e, "communication_pincode")
                }
              />
              <p className="input-error-desc small pt-1">
                {errors?.["communication_pincode"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="communication_pincode"
              control={control}
              label="Pincode"
              type="number"
              defaultValue={formData?.communication_pincode}
              mandatory="true"
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="communication_country"
              control={control}
              label="Country"
              type="single"
              options={countryList}
              errors={errors}
              defaultValue={formData?.communication_country || 105}
              mandatory="true"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12} xl={12}>
            <hr />

            <Stack direction="row" alignItems="center" spacing={2}>
              <h6 className="text-primary">Permanent Address</h6>
              <Switch
                {...label}
                onChange={addressSwtich}
                style={{ marginBottom: "5px" }}
              />
              <p style={{ color: "red" }}>
                * Click Toggle to copy if Permanent address in same as
                Communication address
              </p>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="permanent_address_line_1"
              control={control}
              label="Address Line 1"
              type="text"
              errors={errors}
              defaultValue={formData?.permanent_address_line_1}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="permanent_address_line_2"
              control={control}
              label="Address Line 2"
              type="text"
              errors={errors}
              defaultValue={formData?.permanent_address_line_2}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="permanent_address_line_3"
              control={control}
              label="Address Line 3"
              type="text"
              errors={errors}
              defaultValue={formData?.permanent_address_line_3}
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="permanent_city"
              control={control}
              label="City"
              type="single"
              options={cityList}
              errors={errors}
              defaultValue={formData?.permanent_city}
              mandatory="true"
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="permanent_district"
              control={control}
              label="District"
              type="text"
              errors={errors}
              defaultValue={formData?.permanent_district}
              mandatory="true"
            />
          </Grid> */}
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div>
              <label className="form-label">
                District
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <select
                className="form-select"
                name="permanent_district"
                {...register("permanent_district")}
                onChange={(e) =>
                  permanentDistrictHandler(e.target.value, "permanent_district")
                }
              >
                <option value="">--SELECT--</option>
                {DISTRICT_LIST.map((option) => (
                  <option
                    key={option?.id}
                    value={option?.id}
                    selected={formData?.permanent_district == option?.id}
                  >
                    {option?.name}
                  </option>
                ))}
              </select>
              <p className="input-error-desc small">
                {errors?.["permanent_district"]?.message}
              </p>
            </div>
          </Grid>
          {otherPDistrict && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormInputText
                name="permanent_other_district"
                control={control}
                label="District"
                type="text"
                errors={errors}
                defaultValue={formData?.permanent_other_district}
                mandatory="true"
                className={"capitalize"}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="permanent_state"
              control={control}
              label="State/Province"
              type="single"
              options={stateList}
              errors={errors}
              defaultValue={formData?.permanent_state}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <div className="mb-3">
              <label className="form-label">
                Pincode
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("permanent_pincode")}
                className="form-control"
                placeholder="Eg: 654321"
                maxLength="6"
                defaultValue={formData?.permanent_pincode}
                onChange={(e) => handleNumericChange(e, "permanent_pincode")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["permanent_pincode"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="permanent_pincode"
              control={control}
              label="ZIP/Postal Code"
              type="text"
              defaultValue={formData?.permanent_pincode}
              mandatory="true"
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="permanent_country"
              control={control}
              label="Country"
              type="single"
              options={countryList}
              errors={errors}
              defaultValue={formData?.permanent_country || 105}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={12} xl={12}>
            <hr />
            <h6 className="text-primary">Other Details</h6>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="community"
              control={control}
              label="Community"
              type="single"
              options={[
                {
                  name: "OC",
                  id: "OC",
                },
                {
                  name: "BC",
                  id: "BC",
                },
                {
                  name: "BCM",
                  id: "BCM",
                },
                {
                  name: "MBC",
                  id: "MBC",
                },
                {
                  name: "DNC",
                  id: "DNC",
                },
                {
                  name: "SC",
                  id: "SC",
                },
                {
                  name: "SCA",
                  id: "SCA",
                },
                {
                  name: "ST",
                  id: "ST",
                },
              ]}
              errors={errors}
              defaultValue={formData?.community || ""}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="caste"
              control={control}
              label="Caste"
              type="single"
              options={CASTE_LIST}
              errors={errors}
              defaultValue={formData?.caste || ""}
              mandatory="true"
            />
            {/* <FormInputText
              name="caste"
              control={control}
              label="Caste"
              type="text"
              options={CASTE_LIST}
              defaultValue={formData?.caste}
              mandatory="true"
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="religion"
              control={control}
              label="Religion"
              errors={errors}
              type="text"
              defaultValue={formData?.religion}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputDropdown
              name="blood_group"
              control={control}
              label="Blood Group"
              type="single"
              options={[
                {
                  name: "A+",
                  id: "A+",
                },
                {
                  name: "A-",
                  id: "A-",
                },
                {
                  name: "B+",
                  id: "B+",
                },
                {
                  name: "B-",
                  id: "B-",
                },

                {
                  name: "O+",
                  id: "O+",
                },
                {
                  name: "O-",
                  id: "O-",
                },
                {
                  name: "AB+",
                  id: "AB+",
                },
                {
                  name: "AB-",
                  id: "AB-",
                },
              ]}
              errors={errors}
              defaultValue={formData?.blood_group || ""}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FormInputText
              name="nationality"
              control={control}
              label="Nationality"
              errors={errors}
              type="text"
              defaultValue={formData?.nationality}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
        </Grid>
      </Paper>
      <h5 className="text-primary mt-4">Family Details</h5>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2} columnSpacing={{ xs: 5 }}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <FormInputRadio
              name="parent_father_type"
              control={control}
              label="Choose"
              errors={errors}
              cOptions={[
                {
                  label: "Father",
                  value: "P",
                },
                {
                  label: "Guardian",
                  value: "G",
                },
              ]}
              defaultValue={formData?.parent_father_type}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="father_name"
              control={control}
              label="Father/Guardian Name"
              type="text"
              errors={errors}
              defaultValue={formData?.father_name}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <div className="mb-3">
              <label className="form-label">
                Father/Guardian Mobile
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("father_mobile")}
                className="form-control"
                placeholder="Eg: 987654321"
                maxLength="10"
                defaultValue={formData?.father_mobile}
                onChange={(e) => handleNumericChange(e, "father_mobile")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["father_mobile"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="father_mobile"
              control={control}
              label="Father/Guardian Mobile"
              type="text"
              defaultValue={formData?.father_mobile}
              mandatory="true"
              maxLength={10}
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="father_occupation"
              control={control}
              label="Father/Guardian Occupation"
              type="text"
              errors={errors}
              defaultValue={formData?.father_occupation}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="father_annual_income"
              control={control}
              label="Father/Guardian Annual Income"
              type="text"
              errors={errors}
              defaultValue={formData?.father_annual_income}
              mandatory="true"
            />
          </Grid>

          {/* Mother */}
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <FormInputRadio
              name="parent_mother_type"
              control={control}
              label="Choose"
              errors={errors}
              cOptions={[
                {
                  label: "Mother",
                  value: "P",
                },
                {
                  label: "Guardian",
                  value: "G",
                },
              ]}
              defaultValue={formData?.parent_mother_type}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="mother_name"
              control={control}
              label="Mother/Guardian Name"
              type="text"
              errors={errors}
              defaultValue={formData?.mother_name}
              mandatory="true"
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <div className="mb-3">
              <label className="form-label">
                Mother/Guardian Mobile
                <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
              </label>
              <input
                type="text"
                {...register("mother_mobile")}
                className="form-control capitalize"
                maxLength="10"
                defaultValue={formData?.mother_mobile}
                onChange={(e) => handleNumericChange(e, "mother_mobile")}
              />
              <p className="input-error-desc small pt-1">
                {errors?.["mother_mobile"]?.message}
              </p>
            </div>
            {/* <FormInputText
              name="mother_mobile"
              control={control}
              label="Mother/Guardian Mobile"
              type="text"
              defaultValue={formData?.mother_mobile}
              mandatory="true"
              maxLength={10}
            /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="mother_occupation"
              control={control}
              label="Mother/Guardian Occupation"
              type="text"
              defaultValue={formData?.mother_occupation}
              className={"capitalize"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FormInputText
              name="mother_annual_income"
              control={control}
              label="Mother/Guardian Annual Income"
              type="text"
              defaultValue={formData?.mother_annual_income}
            />
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Button
              color="success"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default PersonalDetails;
