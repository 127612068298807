import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonalDetails from "./personalDetails/PersonalDetails";
import EducationalDetails from "./contact/EducationalDetails";
import UploadDoc from "./upload/UploadDoc";
import SendIcon from "@mui/icons-material/Send";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import CourseDecision from "./courseDecision/CourseDecision";
import toast from "react-hot-toast";

import {
  courseTypeReducer,
  decrement,
  increment,
} from "../../app/store/slice/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

const steps = [
  "Course",
  "Personal Details",
  "Educational Details",
  "Documents Upload",
];
function ApplicationForm() {
  const dispatch = useDispatch();
  const { couseType, stepper } = useSelector((state) => state.counter);
  // const stepper = useSelector((state) => state.counter.stepper);
  const courseType = useSelector((state) => state.counter.courseType);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [activeStep, setActiveStep] = React.useState(stepper);
  const [formStatus, setFormStatus] = useState({});
  const [loader, setLoader] = useState(true);
  const [skipped, setSkipped] = React.useState(new Set());
  const [uploadCompleted, setUploadCompleted] = React.useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    dispatch(increment());
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(decrement());
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <CourseDecision className="mt-5" activeStep={step} />
          </>
        );
      case 1:
        return (
          <>
            <PersonalDetails className="mt-5" activeStep={step} />
          </>
        );
      case 2:
        return (
          <>
            <EducationalDetails className="mt-5" activeStep={step} />
          </>
        );
      case 3:
        return (
          <>
            <UploadDoc
              className="mt-5"
              activeStep={step}
              setUploadCompleted={setUploadCompleted}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  };
  const loadInitialData = async () => {
    if (userData?.uid) {
      await axios
        .post("/getFormStatus", { uid: userData?.uid })
        .then(function (response) {
          if (response?.data?.data) {
            setFormStatus(response?.data?.data);
            // dispatch(courseTypeReducer(response?.data?.data?.course_status));
          }
          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <>
          {/* <Typography className={classes.instructions}> */}
          {getStepContent(activeStep)}
          {/* </Typography> */}

          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                startIcon={<FastRewindIcon />}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== steps.length - 1 && couseType && (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && uploadCompleted && (
                <p
                  className="text-center"
                  style={{ color: "red", fontWeight: 600 }}
                >
                  <i>
                    All profile steps completed. Go to &nbsp;
                    <Link to="/application-list">
                      <b>Application</b>
                    </Link>&nbsp;
                    menu to proceed.
                  </i>
                </p>
              )}
            </Box>
          </React.Fragment>
        </>
      )}
    </Box>
  );
}
export default ApplicationForm;
