import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDate } from "../../../components/form-components/FormInputDate";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import toast from "react-hot-toast";
import { FormInputRadio } from "../../../components/form-components/FormInputRadio";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "../../../layout/sideNav/sideNav";
import { DrawerHeader } from "../preview/PreviewIndex";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { showModal } from "../../../app/store/slice/counterSlice";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentSchema } from "../../../app/schema";

function Payment() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const applicationId = location?.state?.applicationId;
  const collegeId = location?.state?.collegeId;
  const courseId = location?.state?.courseId;

  const { collegeList, courseList, couseType } = useSelector(
    (state) => state.counter
  );
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(true);

  const [courseListForCollege, setCourseListForCollege] = useState([]);
  const [formStatus, setFormStatus] = useState({});
  const [appCourse, setAppCourse] = useState({});

  const [fee, setFee] = useState("");
  const [preview, setPreview] = useState(false);

  const methods = useForm({
    resolver: yupResolver(paymentSchema),
  });
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const onSubmit = (data) =>
    saveForm({
      ...data,
      uid: userData?.uid,
      college_id: collegeId,
      course_id: courseId,
    });

  const saveForm = (data) => {
    if (data?.uid) {
      setLoader(true);
      axios
        .post("/submitPayment", data)
        .then(function (response) {
          if (!response?.data?.error) {
            setLoader(false);
            loadInitialData();
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            setLoader(false);
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch(function (error) {
          setLoader(false);
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const loadInitialData = () => {
    if (userData?.uid) {
      axios
        .post("/getApplicationCoursesById", {
          application_id: applicationId,
          college_id: collegeId,
          course_id: courseId,
        })
        .then(function (response) {
          setAppCourse(response?.data?.data);

          setLoader(false);
        })
        .catch(function (err) {
          toast.error(err?.message);
        });
    } else {
      toast.error("User ID not found");
    }
  };

  const ImageView = () => {
    if (collegeId == 1) {
      return (
        <img
          src={require("../../../assets/qr3.jpeg")}
          className="w-100"
          style={{ padding: "0rem 5rem" }}
        />
      );
    } else if (collegeId == 2) {
      return (
        <img
          src={require("../../../assets/qr1.jpeg")}
          className="w-100"
          style={{ padding: "0rem 5rem" }}
        />
      );
    } else {
      return (
        <img
          src={require("../../../assets/qr2.jpeg")}
          className="w-100"
          style={{ padding: "0rem 5rem" }}
        />
      );
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const handleCourse = (value) => {
    const x = courseList.filter((item) => item?.college_id == value);
    setCourseListForCollege(x);
  };

  useEffect(() => {
    if (appCourse?.college) {
      handleCourse(appCourse?.college);
    }
  }, [appCourse?.college]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {loader ? (
            <Loader />
          ) : (
            <>
              <h5 className="text-primary mt-4">Payment</h5>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  margin: "auto",
                  marginTop: "15px",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={5} xl={5}>
                    <h5>Details Of Transaction</h5>
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={12}>
                        <ul className="text-primary">
                          <li>
                            This payment method is manual, Hence enter all the
                            transaction details carefully for us to verify.
                          </li>
                          <li>Mention the applciation no. in the remarks.</li>
                          <li>
                            Application fees to be paid separately for each
                            course.
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          name="txn_no"
                          control={control}
                          label="UPI transaction ID"
                          type="text"
                          errors={errors}
                          mandatory="true"
                          defaultValue={appCourse?.txn_no}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          name="amount_paid"
                          control={control}
                          label="Amount Paid"
                          type="number"
                          errors={errors}
                          mandatory="true"
                          defaultValue={appCourse?.amount_paid}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputDate
                          name="payment_date"
                          control={control}
                          label="Transaction Date"
                          type="date"
                          errors={errors}
                          mandatory="true"
                          defaultValue={appCourse?.payment_date}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText
                          name="remarks"
                          control={control}
                          label="Remarks"
                          type="text"
                          errors={errors}
                          mandatory="true"
                          defaultValue={appCourse?.remarks}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ display: "none" }}>
                        <FormInputRadio
                          name="payment_type"
                          control={control}
                          label="Payment Type"
                          errors={errors}
                          cOptions={[
                            {
                              label: "UPI",
                              value: "UPI",
                            },
                            {
                              label: "CASH",
                              value: "CASH",
                            },
                            {
                              label: "CARD",
                              value: "CARD",
                            },
                            {
                              label: "CHALLAN",
                              value: "CHALLAN",
                            },
                          ]}
                          defaultValue={appCourse?.payment_type || "UPI"}
                          mandatory="true"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <h6 style={{ fontWeight: 600, color: "red" }}>
                          Fees to be paid: ₹ {appCourse?.fees}
                        </h6>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        container
                      >
                        <Button
                          color="secondary"
                          startIcon={<FastRewindIcon />}
                          variant="contained"
                          onClick={() => navigate(-1)}
                          >
                          Back
                        </Button>
                        &emsp;
                        {userData?.role == 3 && (
                          <Button
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            startIcon={<CurrencyRupeeIcon />}
                          >
                            Submit
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={7}
                    xl={7}
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <ImageView />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Payment;
